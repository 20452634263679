import React from 'react';
import PropTypes from 'prop-types';
import WITBase from '../WITBase';
//import "../../css/xenia-bootstrap-alt.css";
import * as cntrl from '../../wit-components';
import AppContainerVM from './AppContinerVM';

export default class AppContainer extends WITBase {
    static propTypes = {
        className: PropTypes.string,
        id: PropTypes.string,
        url: PropTypes.string,
        data: PropTypes.object,
        context: PropTypes.object,
        bounds: PropTypes.shape({ left: PropTypes.number, top: PropTypes.number, right: PropTypes.number, bottom: PropTypes.number })
    };
    static defaultProps = {
        className: undefined,
        url: '',
        data: undefined,
        bounds: undefined
    };
    constructor(props) {
        super(props, new AppContainerVM());
        this.inputRefs = {};
        this.childCallback = this.childCallback.bind(this);
    }
    onActive = (e) => {
        try {
            if (this.VM) {
                const { Windows } = this.VM.Data;
                if (Windows.length > 0) {
                    var wndw = Windows.last();
                    if (wndw !== null && wndw.childRef && wndw.childRef.onActive) {
                        wndw.childRef.onActive();
                        return;
                    }
                }
            }
            if (this.inputRefs['TabContainer'] && this.inputRefs['TabContainer'].onActive)
                this.inputRefs['TabContainer'].onActive(e);
        } catch (ex) { }
    };
    onInactive = (e) => {
        try {
            if (this.inputRefs['TabContainer'] && this.inputRefs['TabContainer'].onInactive)
                this.inputRefs['TabContainer'].onInactive(e);
        } catch (ex) { }
    };
    onRefChange(el, name) {
        this.inputRefs[name] = el;
    };
    setFocus(name) {
        if (this.inputRefs[name] && this.inputRefs[name].focus)
            this.inputRefs[name].focus();
    }

    childCallback(e) {
        if (this.VM)
            this.VM.childCallback(e);

    }
    getAttr() {
        let id = this.props.id;
        let url = this.props.url;
        let dataInfo = this.props.data;
        let data = dataInfo;
        let context = this.props.context;
        if (context && (context.type === 'tab' || context.type === 'tabbedWindow') && dataInfo && dataInfo.url) {
            url = dataInfo.url;
            data = dataInfo.data;
        }
        return { id, url, data };
    }
    render() {
        let dataInfo = this.props.data;
        let model = this.VM.Data;
        let attr = model.containerOptions;
        if (attr === null) {
            attr = this.getAttr();
            model.containerOptions = attr;
            attr.context = { id: this.props.id, type: this.props.context.type, parentCallback: this.childCallback };
            attr.windows = model.Windows;
            if (dataInfo && dataInfo.bounds)
                attr.bounds = dataInfo.bounds || undefined;

            if (this.props.context.type === 'tab')
                attr.className = 'container-fluid';
        }
        return (<cntrl.WITMdiContainer {...attr} ref={el => this.onRefChange(el, 'TabContainer')} ></cntrl.WITMdiContainer>);
    }
}
