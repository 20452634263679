import React from 'react';
import PropTypes from 'prop-types';
import { WITWindow } from './WITWindow';
import * as witEnum from './WITEnum';

const WITContentWrapper = React.memo((props) => {

  function invokeCallback(callback, e) {
    if (callback && typeof callback === 'function')
      return callback(e);
    return null;
  }
  function invokeParentCallback(e) {
    const { context, id } = props;
    if (context !== undefined && context !== null) {
      const { parentCallback, type } = context;
      if (parentCallback) {
        e.id = id;
        e.type = type;
        invokeCallback(parentCallback, e);
      }
    }
  }

  function handleClose(e) {
    let result = invokeCallback(props.onClose, e);
    if (result && result === false) {

    }
    else {
      invokeParentCallback({ eventName: 'CLOSE', data: result });
    }
  }
  function renderLoading() {
    let inputAttr = { zIndex: witEnum.WITZIndexes.LOADING, display: 'grid', justifyContent: 'center', alignItems: 'center', alignContent: 'center' };
    //, width: '-webkit-fill-available', height: '-webkit-fill-available' 
    let url = `${window.location.origin}/dist/css/images/XENIA.svg`;
    return (<div className="witcontainer-loading" style={inputAttr}>
      <div style={{ width: '100px', height: '100px' }}>
        <img src={url} alt="loading...." ></img>
      </div>
    </div>);
  };
  function renderModalWindow(w) {
    let { context, id, type, ...attr } = props.context;

    const loaderProps = { id, attr };
    loaderProps.title = props.title || 'title';
    loaderProps.isModal = type === 'window';
    loaderProps.isClosable = true;
    if (props.isClosable)
      loaderProps.isClosable = props.isClosable;
    loaderProps.onClose = handleClose;
    loaderProps.showloading = props.showloading;
    loaderProps.windowClass = props.wrapperClass;

    return <WITWindow key={id} {...loaderProps}>
      {props.children}
      {props.showloading && renderLoading()}
    </WITWindow>;
  }
  function renderContent() {
    return (<React.Fragment>
      {props.children}
      {props.showloading && renderLoading()}
    </React.Fragment>);
  }

  function render() {
    if (props.context.type === 'tab')
      return renderContent();
    else
      return renderModalWindow();
  }

  return render();
});

export { WITContentWrapper };

/*
import React from 'react';
import PropTypes from 'prop-types';
import { WITWindow } from './WITWindow';
import * as witEnum from './WITEnum';

export class WITContentWrapper extends React.Component {
  static propTypes = {
    wrapperClass: PropTypes.string,
    title: '',
    showloading: PropTypes.bool,
    onClose: PropTypes.func,
    context: PropTypes.object,
  };
  static defaultProps = {
    wrapperClass: 'modal-lg',//xl, lg, md, sm
    title: '',
    showloading: false
  };

  invokeCallback(callback, e) {
    if (callback && typeof callback === 'function')
      return callback(e);
    return null;
  }
  invokeParentCallback(e) {
    const { context, id } = this.props;
    if (context !== undefined && context !== null) {
      const { parentCallback, type } = context;
      if (parentCallback) {
        e.id = id;
        e.type = type;
        this.invokeCallback(parentCallback, e);
      }
    }
  }

  close(result) {
    this.invokeParentCallback({ eventName: 'CLOSE', data: result });
  }
  handleClose = (e) => {
    let result = this.invokeCallback(this.props.onClose, e);
    if (result && result === false) {

    }
    else {
      this.invokeParentCallback({ eventName: 'CLOSE', data: result });
    }
  }
  renderLoading() {
    let inputAttr = { zIndex: witEnum.WITZIndexes.LOADING, display: 'grid', justifyContent: 'center', alignItems: 'center', alignContent: 'center' };
    //, width: '-webkit-fill-available', height: '-webkit-fill-available'
    return (<div className="witcontainer-loading" style={inputAttr}>
      <div style={{ width: '100px', height: '100px' }}>
        <img src="" alt="" ></img>
      </div>
    </div>);
  };
  renderModalWindow(w) {
    let { context, id, ...attr } = this.props.context;

    const loaderProps = { id, attr };
    loaderProps.title = this.props.title || 'title';
    loaderProps.isModal =
      loaderProps.isClosable = true;
    if (this.props.isClosable)
      loaderProps.isClosable = this.props.isClosable;
    loaderProps.onClose = this.handleClose;
    loaderProps.showloading = this.props.showloading;
    loaderProps.windowClass = this.props.wrapperClass;

    return <WITWindow key={id} {...loaderProps}>
      {this.props.children}
      {this.props.showloading && this.renderLoading()}
    </WITWindow>;
  }
  renderContent() {
    return (<React.Fragment>
      {this.props.children}
      {this.props.showloading && this.renderLoading()}
    </React.Fragment>);
  }

  render() {
    if (this.props.context.type === 'container')
      return this.renderContent();
    else
      return this.renderModalWindow();
  }
}

*/