import * as React from 'react';
import * as PropTypes from 'prop-types';
import $ from 'jquery';

export class WITFile extends React.Component {

    /*
        source :[{name:'samle.png'}]
        isMultiFile:true/false
        onFileChange : (e,files)=>{
            e=> {type,name}
            files=>[{orginal file}]
        }
        name:string html name attr
    */
    static propTypes = {
        source: PropTypes.any,
        isMultiFile: PropTypes.bool,
        onFileChange:PropTypes.func,
        name:PropTypes.string,
        tabIndex:PropTypes.string
    };
    static defaultProps = {
        source: undefined,
        isMultiFile: false,
        onFileChange:undefined,
        tabIndex:"0"
    };
    constructor(props) {
        super(props);
        this.doAssignDefaultState();
    }
    doAssignDefaultState() {
        let { source, isMultiFile } = this.props;
        this.state = {
            source,
            isMultiFile
        };
    }
    componentWillReceiveProps(props) {
        let { source, isMultiFile } = props;
        this.setState({ source, isMultiFile });
    }
    focus=(open)=>
    {
        this.WitFile.focus();
        if(open)
        {
            this.Files.click();
        }
    }
    getFiles = () => {
        let { files } = this.Files;
        return files;
    }
    onMouseDown = (e) => {
        if (e.button && e.button !== 0) {
            return;
          } 
        this.Files.click();
    }
    onFileChange = () => {
      
        let files = this.getFiles();
        if(files.length>0)
        { 
            let data= Object.assign([],files);
            this.setState({ source: data });
            this.Files.value="";
            let {onFileChange,name}=this.props;
            if(onFileChange!==undefined)
            {                
                onFileChange({e:{type:'FILE-CHANGE',name:name}},data);
            }
        }
    }
    renderFileControl() {
        let { isMultiFile } = this.state;
        let attributes = {};
        if (isMultiFile) {
            attributes = {
                multiple: 'multiple'
            };
        }

        return <input onChange={this.onFileChange} title="" type="file" {...attributes} ref={ref => this.Files = ref} />;
    }
    renderFileNames() {      
        let { source } = this.state;
        let fileNames = '';
        if (source && source.length > 0) {         
            let fileCaptions=[];
            $(source).each((index,item)=>{
                fileCaptions.push(item.name);
            });                 
            fileNames = fileCaptions.join(",");
        }
        return <span wit-title={fileNames}>{fileNames}</span>;
    }
    render() {

        return (<div className="wit-file-input" ref={ref=>this.WitFile=ref} tabIndex={this.props.tabIndex} onMouseDown={this.onMouseDown} >
            {this.renderFileControl()}
            {this.renderFileNames()}
            <i tabIndex="0" className="fa fa-cloud-upload pull-right"></i>
        </div>);
    };
}







