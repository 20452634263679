import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { WITMessagebox } from '../WITMessagebox';
import { AsyncLoader } from '../AsyncLoader';
import * as witEnum from '../WITEnum';
import './WITMdiContainer.css';
import $ from 'jquery';

export class WITMdiContainer extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        url: PropTypes.string,
        data: PropTypes.object,
        context: PropTypes.object,
        windows: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isActive: PropTypes.bool.isRequired,
            url: PropTypes.string,
            data: PropTypes.object,
            showloading: PropTypes.bool
        })),
        showloading: PropTypes.bool,
        bounds: PropTypes.shape({ left: PropTypes.number, top: PropTypes.number, right: PropTypes.number, bottom: PropTypes.number })
    };
    static defaultProps = {
        className: undefined,
        url: '',
        data: undefined,
        context: undefined,
        windows: [],
        bounds: undefined
    };
    constructor(props) {
        super(props);
        this.childContainerRef = null;
        this.onDynamicLoaded = this.onDynamicLoaded.bind(this);
        this.keyUpEventHandler = this.keyUpEventHandler.bind(this);
        //this.componentCallback =  this.componentCallback.bind(this);
    }
    wndowOptions = null;
    dynamicOptions = null;
    componentWillUnmount() {
      this.wndowOptions= null;
      this.dynamicOptions= null;
    }
    onActive = (e) => {
        try {
            if (this.childContainerRef && this.childContainerRef.onActive) {
                this.childContainerRef.onActive(e);
            }
        } catch (ex) { }
    };
    onInactive = (e) => {
        try {
            if (this.childContainerRef && this.childContainerRef.onInactive) {
                this.childContainerRef.onInactive(e);
            }
        } catch (ex) { }
    };
    onDynamicLoaded = (e) => {
        this.childContainerRef = e;
    }
    invokeCallback(e) {
        const { context } = this.props;
        if (context) {
            const { parentCallback } = context;
            if (parentCallback && typeof parentCallback === 'function') {
                parentCallback(e);
            }
        }
    }
    componentCallback(args, e) {
        if (args.eventName === 'WINDOW-CLOSE') {
            args.component = e.component || null;
            this.invokeCallback(args);
        }
        else if (args.eventName === 'MBOX-CLOSE') {
            args.data = e.index;
            this.invokeCallback(args);
        }
        else if (args.eventName === 'TAB-CLOSE') {
            this.invokeCallback({ eventName: 'TAB-CLOSE', id: args.id });
        }
    }
    keyUpEventHandler(e) {
        if (e.keyCode === 27) {
            console.log('mdi - handleEscape');
            const { id } = this.props;
            e.preventDefault();
            e.stopPropagation();
            this.invokeCallback({ eventName: 'ESC', type: 'keyup', id: id });
        }
    };
    renderLoading() {
        let inputAttr = { zIndex: witEnum.WITZIndexes.LOADING, display: 'grid', justifyContent: 'center', alignItems: 'center', alignContent: 'center' };
        //, width: '-webkit-fill-available', height: '-webkit-fill-available'
        return (<div className="witcontainer-loading" style={inputAttr}>
            <div style={{ width: '100px', height: '100px' }}>
                <img src="" alt="" ></img>
            </div>
        </div>);
    };
    renderMask() {
        let inputAttr = { zIndex: witEnum.WITZIndexes.MASK };
        return (<div className="witwindow-mask" style={inputAttr}></div>);
    };
    renderWindow(w, tabbedWindow, wIndex) {
        let { id, url, data, context, ...attr } = w;

        if (url === undefined || url === null || url === '')
            return null;
        let wndwOptn =  w.___options;
        if (wndwOptn === undefined || wndwOptn === null) {
            wndwOptn =  {};
            w.___options = wndwOptn;
            wndwOptn.url = url;
            wndwOptn.id = id;
            wndwOptn.data = data;
                wndwOptn.childRef = (el) => {
                    if (tabbedWindow === true)
                    this.childContainerRef = el;
                    else
                    w.childRef = el;
              
                }
        }

        if (tabbedWindow === true)
            wndwOptn.context = { id: id, type: 'tabbedWindow', parentCallback: context.parentCallback, ...attr, childRef: this.onDynamicLoaded };
        else {
            wndwOptn.context = { id: id, type: 'window', parentCallback: this.props.context.parentCallback, ...attr };
        }
        return (<AsyncLoader key={id}  {...wndwOptn} />);
    }
    renderMessagebox(w, wIndex) {
        const { data, id } = w;
        let loaderProps = { id: id, text: data.text, messageboxType: data.messageboxType, buttons: data.buttons, onClose: this.componentCallback.bind(this, { id: id, eventName: 'MBOX-CLOSE' }) };
        return <WITMessagebox key={id}  {...loaderProps} />;
    }
    renderWindows() {
        const { windows } = this.props;
        return windows.map((w, wIndex) => w.isMessagebox === true ? this.renderMessagebox(w, wIndex) : this.renderWindow(w, false, wIndex));
    }
  
    renderAsyncBody() {
        const { id, url, data, context } = this.props;
        if (url) {
            if (context.type === 'tab') {
                let loaderProps = this.dynamicOptions;
                if (this.dynamicOptions === null) {
                    loaderProps = { id, url, data, context };
                    this.dynamicOptions = loaderProps;
                }
                return (<AsyncLoader {...loaderProps} childRef={this.onDynamicLoaded} />);
            }
            else {
                let wndowOpt = this.wndowOptions;
                if (wndowOpt === null) {
                    wndowOpt = {};
                    this.wndowOptions = wndowOpt;
                    wndowOpt.id = id;
                    wndowOpt.url = url;
                    wndowOpt.data = data;
                    wndowOpt.showloading = false;
                    wndowOpt.isModal = false;
                    wndowOpt.context = context;
                }
                return this.renderWindow(wndowOpt, true)
            }
        }
    }
    render() {
        const containerAttr = { onKeyDown: this.keyUpEventHandler, style: { userSelect: 'none', outline: 'none', height: '100%' } };
        let classList = ['wit-container'];
        //if (this.props.class)
        //classList.push(this.props.class);
        return (<div className={classList.join(' ')} {...containerAttr} tabIndex="0">
            {this.props.children}
            {this.renderAsyncBody()}
            {this.renderWindows()}
            {this.props.showloading && this.renderLoading()}
        </div>);

    }
}