import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { DOMUtil } from '../WITUtils';
import $ from 'jquery';

export class WITTabHeaderPanel extends React.Component {
    static propTypes = {
        autoOverflowMenu: PropTypes.bool,
        tabItems: PropTypes.array,
        tabWidth: PropTypes.string,
        maximumItems: PropTypes.number,
        parentCallback: PropTypes.func,
        isBottom: PropTypes.bool
    };
    static defaultProps = {
        autoOverflowMenu: false,
        tabItems: [],
        tabWidth: 'auto',
        maximumItems: undefined,
        isBottom: false
    };
    state = { noOfTabs: null, showPopup: false };

    componentDidMount() {
        window.setTimeout(() => { this.setWidth(); }, 200)
        $(document).on('mouseup.wittab', this.onMouseUp);
    }
    componentWillUnmount() {
        $(document).off('mouseup.wittab', this.onMouseUp);
    }

    setWidth() {
        let tabWidth = 125;
        let width = 1000;
        const node = ReactDOM.findDOMNode(this);
        if (node) {
            width = DOMUtil.width($(node));
            const noOfTabs = parseInt((width - 22) / tabWidth);
            this.setState({ noOfTabs });
        }
    }

    handleMouseEnter = () => {
        this.IsInside = true;
    };
    handleMouseLeave = () => {
        this.IsInside = false;
    };
    onMouseUp = (evt) => {
        if (this.IsInside === true)
            return;
        const { showPopup } = this.state;
        if (showPopup === true)
            this.setState({ showPopup: false });
    };
    componentCallback(e, tab, eventName) {
        if (tab) {
            if (eventName === 'TAB-CLOSE')
                e.stopPropagation();
            let { noOfTabs } = this.state;
            if (this.props.parentCallback)
                this.props.parentCallback({ eventName: eventName, tab: tab, noOfVisibleTabs: noOfTabs });
        }
        else {
            this.setState({ showPopup: !this.state.showPopup });
        }
    }
    renderItem(tab, index) {
        let classList = "";
        if (tab.isActive === true)
            classList = "active";
        let isClosable = true;
        if (tab.isClosable !== undefined && tab.isClosable !== null)
            isClosable = tab.isClosable === true;

            let toolTip = tab.toolTip || ''

        return (<li style={{ width: '125px' }} title={toolTip} key={`tbitm_${tab.id}`} className={classList} onClick={(e) => this.componentCallback(e, tab, 'TAB-SELECTED')}>
            <span>{tab.text}</span>
            {isClosable && <span className="clear-tab   " title="Close" onClick={(e) => this.componentCallback(e, tab, 'TAB-CLOSE')}>&times;</span>}
        </li>);
    }
    renderMoreItem(tab, index) {
        let classList = "dropdown-item";
        let isClosable = true;
        if (tab.isClosable !== undefined && tab.isClosable !== null)
            isClosable = tab.isClosable === true;
        return (<a key={`tbitm_${tab.id}`} href="javascript:void(0)" className={classList} onClick={(e) => this.componentCallback(e, tab, 'TAB-SELECTED')}>
            {isClosable && <span className="clear-tab" title="Close" onClick={(e) => this.componentCallback(e, tab, 'TAB-CLOSE')}>×</span>}
            <span>{tab.text}</span>
        </a>);
    }
    renderMoreTabs(moreTabs) {
        const { showPopup } = this.state;
        return (<li className="more " onClick={(e) => this.componentCallback(e, null)} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} >
            <i className="fa fa-caret-down"></i>
            {showPopup && <div className="dropdown-menu show dropdown-menu-right mr-1" >
                {moreTabs.map((tab, index) => this.renderMoreItem(tab, index))}
            </div>
            }
        </li>);
    }
    renderBottomMoreTabs(moreTabs) {
        const { showPopup } = this.state;
        return (<li className="more " onClick={(e) => this.componentCallback(e, null)} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} >
            <i className="fa fa-caret-up"></i>
            {showPopup && <div style={{ top: `calc(-${26 * moreTabs.length}px - 1.5rem)` }} className="dropdown-menu show dropdown-menu-right mr-1"  >
                {moreTabs.map((tab, index) => this.renderMoreItem(tab, index))}
            </div>
            }
        </li>);
    }
    render() {
        let { noOfTabs } = this.state;
        if (noOfTabs === null) {
            noOfTabs = this.props.tabItems.length;
        }
        let normalTabs = [];
        let moreTabs = [];

        this.props.tabItems.forEach((element, index) => {
            if (index < noOfTabs)
                normalTabs.push(element);
            else
                moreTabs.push(element);
        });

        let moreTabComponent = null;
        if (moreTabs.length)
            moreTabComponent = (this.props.isBottom === true) ? this.renderBottomMoreTabs(moreTabs) : this.renderMoreTabs(moreTabs);

        return (<div className="main-tab-header">
            <ul>
                {normalTabs.map((tab, index) => this.renderItem(tab, index))}
                {moreTabComponent}
            </ul>
        </div>);
    }
}