import React from 'react';
import PropTypes from 'prop-types';
import * as witEnum from '../WITEnum';
import {TextboxUtil} from '../WITUtils';

export class WITTextboxWrapper extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        inputType: PropTypes.oneOf(Object.keys(witEnum.WITTextboxTypes)),
        prefix: PropTypes.number,
        suffix: PropTypes.number,
        numericType: PropTypes.oneOf(Object.keys(witEnum.WITNumericTypes)),
        maxLength: PropTypes.number,
        mandatory: PropTypes.bool,
        onChange: PropTypes.func,
        isDatePicker: PropTypes.bool,
        formatOnBlur: PropTypes.bool,
        events: PropTypes.object
    };
    static defaultProps = {
        name: '',
        inputType: witEnum.WITTextboxTypes.textbox,
        numericType: 'both',
        prefix: 3,
        suffix: 2,
        value: '',
        onChange: undefined,
        maxLength: undefined,
        isDatePicker: false,
        formatOnBlur: true,
        events: undefined,
    };
    state = { value: this.props.value };

    componentWillReceiveProps(props) {
        this.setState({ value: props.value });
    }

    focus() {
        if (this.textInput)
            this.textInput.focus();
    };

    //https://www.w3resource.com/javascript/form/credit-card-validation.php
    //email::- /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    //phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    getRegExp() {
        let testers = [];
        if (this.props.inputType === witEnum.WITTextboxTypes.numeric) {
            var prefix = this.props.prefix;
            var suffix = this.props.suffix;
            var neg = "";
            if (this.props.numericType === 'both')
                neg = '(-)?';
            else if (this.props.numericType === 'negative')
                neg = '-';
            if (prefix !== 0 || suffix !== 0) {
                if (suffix <= 0) {
                    testers.push(new RegExp('^' + neg + '[0-9]{0,' + prefix + '}$'));
                }
                else {
                    testers.push(new RegExp('^' + neg + '[0-9]{0,' + prefix + '}\\.[0-9]{0,' + suffix + '}$|^' + neg + '[0-9]{0,' + prefix + '}\\.$|^' + neg + '[0-9]{0,' + prefix + '}$'));
                }
            }
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric) {
            testers.push(new RegExp('^[0-9a-zA-Z]+$'));
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.time) {
            testers.push(new RegExp('^([0-9]|[0-1][0-2])(:([0-5]|[0-5][0-9])?)?$'));
            testers.push(new RegExp('^([0-9]|[0-1][0-2])(.([0-5]|[0-5][0-9])?)?$'));
        }
        else if (this.props.inputType == witEnum.WITTextboxTypes.time24hr) {
            testers.push(new RegExp('^([0-9]|[0-1][0-9]|[2][0-3])(:([0-5]|[0-5][0-9])?)?$'));
            testers.push(new RegExp('^([0-9]|[0-1][0-9]|[2][0-3])(.([0-5]|[0-5][0-9])?)?$'));
        }
        return testers;
    };

    invokeOnChange(evt, value) {
        if (this.props["onChange"]) {
            this.props["onChange"]({
                event: evt,
                value: value,
                eventName: "onChange",
                name: this.props.name
            });
        }

    };
    invokeCallback(callbackName, evt) {

        if (callbackName === "onChange") {
            this.invokeOnChange(evt, evt.target.value);
        }
        else if (callbackName == "onClear") {
            if (this.props[callbackName]) {
                this.props[callbackName]({
                    event: null,
                    value: '',
                    eventName: callbackName,
                    name: this.props.name
                });
            }
        }
        else {
            if (this.props.events && typeof this.props.events[callbackName] === "function") {
                this.props.events[callbackName]({
                    event: evt,
                    value: evt.target.value,
                    eventName: callbackName,
                    name: this.props.name
                });
            }
        }
    };
    handleChange = (evt, eventName) => {
         
        if (this.props.inputType === witEnum.WITTextboxTypes.titleCase) {
            TextboxUtil.titleCaseInput(evt);
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.sentenceCase) {
            TextboxUtil.sentenceCaseInput(evt);
        }
        else {

            let value = evt.target.value;
            if (value.length === 0)
                return;
            let testers = this.getRegExp();
            if (testers.length > 0) {
                var failedResults = testers.filter(i => i.test(value));
                if (failedResults.length === 0) {
                    evt.target.value = this.state.value;
                }
            }
        }
        this.invokeCallback('onChange', evt);
    };

    clearText = () => {
        this.setState({ value: '' });
        this.invokeCallback('onClear', null);
        this.invokeOnChange(null, "");
    };
    onChange = (evt) => {
        let val = evt.target.value;
        if (this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric)
            val = val.toUpperCase();
        this.setState({ value: val });
        this.invokeCallback('onChange', evt);
    };
    onBlur = (evt) => {
        let onChangeEvent = false;
        if (this.props.inputType === witEnum.WITTextboxTypes.numeric) {
            let value = evt.target.value;
            let val = value;
            if (this.props.formatOnBlur === true)
                val = value ? parseFloat(value).toFixed(this.props.suffix) : '';
            if (isNaN(val)) {
                value = '';
            }
            else
                value = val;

            if (evt.target.value !== value)
                onChangeEvent = true
            evt.target.value = value;
            this.invokeCallback('onBlur', evt);
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric) {
            //evt.target.value = evt.target.value;
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.time || this.props.inputType === witEnum.WITTextboxTypes.time24hr) {
            let text = evt.target.value;
            if (text.length !== 0) {
                var splitChar = text.indexOf(":") > -1 ? ':' : text.indexOf(".") > -1 ? '.' : ' ';
                if (splitChar === ' ') {
                    if (text.length > 4)
                        text = text.substr(0, 4);
                    if (text.length === 1)
                        text = text.padStart(2, '0');
                    text = text.padEnd(4, '0');
                }
                else {
                    var data = text.split(splitChar);
                    text = data[0].padStart(2, '0');
                    text = text.substr(text.length - 2);
                    text += data[1].padEnd(2, '0').substr(0, 2);
                }
                var hr = +(text.substr(0, 2));
                var min = +(text.substr(2, 2));

                if (hr < 24 && min < 60)
                    text = text.substr(0, 2) + ":" + text.substr(2);
                else
                    text = "";

                if (evt.target.value !== text)
                    onChangeEvent = true
                evt.target.value = text;
            }
        }
        if (onChangeEvent === true)
            this.invokeCallback('onChange', evt);
        this.invokeCallback('onBlur', evt);
    };
    onPaste = (evt) => {
        var items = evt.clipboardData.items;
        if (items[0].kind === 'string') {
            var clipboardData = evt.clipboardData || window.clipboardData;
            var pastedData = clipboardData.getData('Text');
            var val = null;
            if (this.props.inputType === witEnum.WITTextboxTypes.titleCase)
                val = TextboxUtil.titleCasePasteInput(evt, pastedData);
            else if (this.props.inputType === witEnum.WITTextboxTypes.sentenceCase)
                val = TextboxUtil.sentenceCasePasteInput(evt, pastedData);
            if (val != null) {
                this.onChange(evt);
            }
        }
    }
    onKeyPress = (evt) => {
        this.handleChange(evt, "onKeyPress");
    };
    onInput = (evt) => { this.handleChange(evt, "onInput"); };
    
    
    setEvents(attr) {
        /*attr.onChange=this.onChange ;

        if (this.props.inputType === witEnum.WITTextboxTypes.titleCase || this.props.inputType === witEnum.WITTextboxTypes.sentenceCase) {
            attr.onKeyPress = this.onKeyPress;
            attr.onPaste = this.onPaste;
        }
        if (this.props.inputType === witEnum.WITTextboxTypes.numeric || this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric || this.props.inputType === witEnum.WITTextboxTypes.time || this.props.inputType === witEnum.WITTextboxTypes.time24hr) {
            attr.onInput = this.onInput;
        }
        attr.onBlur = this.onBlur;*/

        let events = { onChange: this.onChange };

        if (this.props.inputType == witEnum.WITTextboxTypes.titleCase || this.props.inputType == witEnum.WITTextboxTypes.sentenceCase) {
            events.onKeyPress = this.onKeyPress;
            events.onPaste = this.onPaste;
        }
        if (this.props.inputType == witEnum.WITTextboxTypes.numeric || this.props.inputType == witEnum.WITTextboxTypes.alphaNumeric || this.props.inputType == witEnum.WITTextboxTypes.time || this.props.inputType == witEnum.WITTextboxTypes.time24hr) {
            events.onInput = this.onInput;
        }
        events.onBlur = this.onBlur;
        Object.assign(attr, this.props.events, events);
    }
    

    renderChildrens() {
        let attr = {};
        let textClassList = [];
        this.setEvents(attr);
        if (this.props.maxLength)
            attr.maxLength = this.props.maxLength || 0;


        if (this.props.inputType === witEnum.WITTextboxTypes.numeric) {
            if (this.props.suffix && this.props.suffix > 0) {
                textClassList.push('text-right');
                attr.maxLength = this.props.prefix + this.props.suffix + 1 + ((this.props.numericType === 'negative' || this.props.numericType == 'both') ? 1 : 0);
            }
            else {
                textClassList.push('text-center');
                attr.maxLength = this.props.prefix || 0;
            }
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric)
            textClassList.push('uppercase');
            
            if(textClassList.length !== 0)
            attr.className = textClassList.join(' ');

        const children = this.props.children;
        return React.Children.map(children, (child,index) =>
        {
            let attrCopy = Object.assign({}, attr);
            if(child.props.className && attrCopy.className)
            attrCopy.className = child.props.className + ' ' + attrCopy.className;
              
                attrCopy.key = index;
          return  React.cloneElement(child, attrCopy);
        });
    };
    render()
    {
        return this.renderChildrens();

    }
};