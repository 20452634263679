import $ from 'jquery'
import React, { useState } from "react";
import ReactDOMServer from 'react-dom/server';
import {findDOMNode} from 'react-dom';
export class Tooltip  {   
  timeoutHandler=null;
  tooltipContext=null;
  context={
    domain:"xen-tooltip",
    role:"[role='xen-tooltip']",
    delay:1000,
    uiContext:null,
    attributes:{
      render:'data-tooltip-render',
      params:'data-tooltip-render-params',
      onhide:'data-tooltip-onhide',
      onshow:'data-tooltip-onshow',
      title:'data-tooltip-title'      
    },
    runtime:{
      render:null,
      params:null,
      onhide:null,
      onshow:null,
      title:null
    }
  };

  constructor(context,delay=1000){
   
    this.initilize(context);
    this.context.delay=delay||1000;
  }

  handleMouseEnter=(elm)=>
  {
   
    if(this.timeoutHandler){
      window.clearTimeout(this.timeoutHandler);
    }

    let runtime=this.context.runtime;
    let attribs=this.context.attributes;

    let target=$(elm.target);
    runtime.render =target.attr(attribs.render)||null ;
    runtime.params =target.attr(attribs.params)||null ;
    runtime.onhide =target.attr(attribs.onhide)||null ;
    runtime.onshow =target.attr(attribs.onshow)||null ;
    runtime.title =target.attr(attribs.title)||null ;
    let renderComponentHtml=null;
    if(this.context.uiContext)
    {
      if(runtime.render)
      {
        let component=this.context.uiContext[runtime.render](runtime.params);
        renderComponentHtml= $(ReactDOMServer.renderToString(component));
      }
    }
    if(!renderComponentHtml||renderComponentHtml.length<=0)
    {
      renderComponentHtml=$('<div class="xen-tooltip-wrapper position-absolute shadow rounded bg-white p-2" />');
      renderComponentHtml.append(runtime.title||'');
      renderComponentHtml.css({"min-width":target.width()+"px"});
      renderComponentHtml.css({"z-index":"1060"});
    }
    if(renderComponentHtml&&renderComponentHtml.length>0){
      renderComponentHtml.addClass(this.context.domain);
    }
    let position={
      left: target.offset().left,
      top:  target.offset().top+target[0].clientHeight
    }

   // if($(`.${this.context.domain}`).length<=0)
    {
      $(`.${this.context.domain}`).remove();
      $('body').append(renderComponentHtml.css(position));
      if(runtime.onshow)
      {
        this.context.uiContext[runtime.onshow]("onshow",renderComponentHtml);
      }
    }   
   
  }
  handleMouseLeave=(elm)=> {
    this.timeoutHandler=window.setTimeout(()=>{
      $(`.${this.context.domain}`).remove();
        if( this.context.runtime.onhide)
        {
          this.context.uiContext[this.context.runtime.onhide]("onhide");
        }
    },this.context.delay);
  }
   initilize(context){   
    this.context.uiContext=context;
    var domNode=findDOMNode(this.context.uiContext);
    $(domNode).on('mouseleave',this.context.role,this.handleMouseLeave);
    $(domNode).on('mouseenter',this.context.role,this.handleMouseEnter);
    
   }
}
