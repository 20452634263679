import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import $ from 'jquery';
import { isFunction } from 'util';
import './WITColorPicker.css';
class WITColorPicker extends React.Component {

  constructor(props) {
    super(props);
  }
  state = {
    displayColorPicker: false,
    color: '#FFF ',
    placeholder: 'Please Select'
  };
  componentDidMount() {
    let { color, placeholder } = this.props;
    this.setState({ color, placeholder: placeholder || 'Please Select' });
  }
  componentWillReceiveProps(props) {
    this.setState({ color: props.color, placeholder: props.placeholder });
  }
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
    let { onChange } = this.props;
    if (onChange && isFunction(onChange)) {
      onChange({ color: color.hex });
    }
  };

  render() {
    const styles = {
      color: {
        color: `${this.state.color}`,
      }
    };
    return (
      <div className="wit-color-picker form-control">
        <div className="color-swatch" onClick={this.handleClick}>
          {this.state.color && <span className="fa fa-square mr5" style={{ color: this.state.color }}></span>}
          <span>{this.state.color || this.state.placeholder}</span>
          {this.state.color && <span className="pull-right" onClick={e => { this.handleChange({ color: { hex: null } }); e.preventDefault(); e.stopPropagation(); }} style={{ marginRight: '-0.625rem', lineHeight: '1.5' }}>&times;</span>}
        </div>
        {this.state.displayColorPicker ? <div className="color-menu">
          <div className="cover" onClick={this.handleClose} />
          <SketchPicker color={this.state.color || '#000'} onChange={this.handleChange} />
        </div> : null}
      </div>
    )
  }
}

export { WITColorPicker };