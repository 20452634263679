import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { DOMUtil } from '../WITUtils';
import $ from 'jquery';

const appRoot = document.getElementById('root');

//https://alligator.io/react/using-new-portal-feature-in-react/
//https://reactjs.org/docs/portals.html
const POPUP_ZINDEX = 107;
export class WITDropDown extends React.Component {
    static propTypes = {
        parent: PropTypes.object,
        isRightAlign: PropTypes.bool,
        isBottomAlign: PropTypes.bool,
        parentCallback: PropTypes.func,
        left: PropTypes.number,
        top: PropTypes.number,
    };
    static defaultProps = {
        parent: undefined,
        isBottomAlign: true,
        isRightAlign: false,
        parentCallback: undefined,
        left: undefined,
        top: undefined
    };
    constructor(props) {
        super(props);
        this.state = { left: '0px', top: '0px' };
    }
    componentDidMount() {
        if (this.props.parentCallback)
            $(document).on('mouseup.witdropdown', this.onMouseUp);
        const node = ReactDOM.findDOMNode(this);
        if (node) {
            var loc = this.getLocation();
            this.setState({ left: loc.left, top: loc.top });
        }
    };
    componentWillUnmount() {
        if (this.props.parentCallback)
            $(document).off('mouseup.witdropdown', this.onMouseUp);
    };

    onMouseUp = (evt) => {
        if (this.props.parentCallback)
            this.props.parentCallback({ eventName: 'DROP_DOWN-MOUSE_UP', event: evt });
        //var datepickers = $(evt.target).closest('.witdatepicker-popup,.witdatepicker');
        //if (datepickers.length == 0 || $(datepickers[0]).attr('cntrl-id') != this.state.id.toString()) {
        //    if (this.state.isOpen != false)
        //        this.setState({ isOpen: false });
        //}
    };

    getLocation() {
        let location = { left: '0px', top: '0px' };
        const node = ReactDOM.findDOMNode(this);
        if (node) {
            const child = $(node);
            const parent = ReactDOM.findDOMNode(this.props.parent);
            const parentNode = $(parent);
            const left = DOMUtil.getLeft(parentNode, child, this.props.isRightAlign);
            const top = DOMUtil.getTop(parentNode, child, this.props.isBottomAlign);
            location = { left: left + 'px', top: top + 'px' };
        }
        return location;
    }

    renderChildren() {
        //, zIndex: POPUP_ZINDEX 
        let style = { left: this.state.left, top: this.state.top, position: 'absolute', zIndex: 1102 };
        if (this.props.left)
            style = { left: this.props.left, top: this.props.top, position: 'absolute', zIndex: 1102 };


        const children = this.props.children;
        return React.Children.map(children, child =>
            React.cloneElement(child, { style: style }));
    };

    render() {
        return ReactDOM.createPortal(
            this.renderChildren(),
            appRoot
        );
    }
};