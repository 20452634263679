import React, { Component } from 'react';
///import './WITTab.css';
import PropTypes from 'prop-types';
import { WITTabHeaderPanel } from './WITTabHeaderPanel';
import { AsyncLoader } from '../AsyncLoader';

export class WITTab extends Component {
    static propTypes = {
        tabItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired,
            headerIcons: PropTypes.arrayOf(PropTypes.string),
            text: PropTypes.string.isRequired,
            description: PropTypes.string,
            url: PropTypes.string,
            data: PropTypes.object,
            tooltip: PropTypes.string,
            destroyOnHide: PropTypes.bool,
            isClosable: PropTypes.bool
        })),
        orientation: PropTypes.string,
        context: PropTypes.object,
        tabWidth: PropTypes.string,
        maximumItems: PropTypes.number,
        autoOverflowMenu: PropTypes.bool,
        onSelectionChanged: PropTypes.func.isRequired,
        onClosing: PropTypes.func.isRequired,
        windowContainter: PropTypes.bool
    };
    static defaultProps = {
        tabItems: [],
        tabWidth: 'auto',
        autoOverflowMenu: false,
        maximumItems: 3,
        isClosable: true,
        orientation: 'top',//bottom, top
        windowContainter: false
    };
    constructor(props) {
        super(props);
        this.componentCallback = this.componentCallback.bind(this);
        //this.keyUpEventHandler = this.keyUpEventHandler.bind(this);
        //this.onKeyDown = this.keyUpEventHandler.bind(this);
    }
    handleCallback(callback, e) {
        if (callback && typeof callback === "function") {
            callback(e);
        }
    }
    keyUpEventHandler = (evt) => {
        if (evt.keyCode === 27) {
            const tabIndex = this.props.tabItems.findIndex(tab => tab.isActive === true);
            if (tabIndex > -1) {
                const tab = this.props.tabItems[tabIndex];
                this.handleCallback(this.props.onClosing, { eventName: 'TAB-CLOSING', id: tab.id });
            }
        }
    };
    componentCallback(e) {
        if (e.eventName === 'TAB-SELECTED') {
            this.handleCallback(this.props.onSelectionChanged, { eventName: 'TAB-SELECTING', id: e.tab.id, noOfVisibleTabs: e.noOfVisibleTabs });
        }
        else if (e.eventName === 'TAB-CLOSE') {
            this.handleCallback(this.props.onClosing, { eventName: 'TAB-CLOSING', id: e.tab.id });
        }
    }
    onDynamicLoaded(e, tab) {
        tab.child = e;
    }
    renderTabBody(tab, tIndex) {

        if (tab.isActive === true || tab.destroyOnHide === false) {
            let classList = 'main-tab-content p-1 pl-3';
            if (this.props.orientation === 'top')
                classList = 'main-tab-content p-1';

            if (tab.isActive === false)
                classList = 'main-tab-content hidden';
            let loaderProps = tab.___options;

            if (loaderProps === undefined || loaderProps === null) {
                loaderProps = { url: tab.url, id: tab.id, data: tab.data, context: this.props.context };
                tab.___options = loaderProps;
            }

            return (<div key={`tab_${tab.id}`} className={classList}>
                <AsyncLoader childRef={(e) => this.onDynamicLoaded(e, tab)} key={tab.id} {...loaderProps} />
            </div>);
        }
        else
            return null;
    };
    render() {
        let headerAttr = {};
        headerAttr.tabItems = this.props.tabItems;
        headerAttr.parentCallback = this.componentCallback;
        headerAttr.isBottom = this.props.orientation !== 'top';

        const containerAttr = {};
        //containerAttr.onKeyUp = this.keyUpEventHandler;
        containerAttr.onKeyDown = this.keyUpEventHandler;

        if (this.props.orientation === 'top') {
            return (<div className="main-tab-container" {...containerAttr} tabIndex="0">
                <WITTabHeaderPanel {...headerAttr} />
                {headerAttr.tabItems.map((tb, index) => this.renderTabBody(tb, index))}
            </div>);
        }
        else {
            return (<div className="main-tab-container scroll-view bottom-nav " {...containerAttr} tabIndex="0">
                  {headerAttr.tabItems.map((tb, index) => this.renderTabBody(tb, index))}
                <WITTabHeaderPanel {...headerAttr} />
            </div>);
        }
    }
}
