import React from 'react';
import { DropMenu, DropMenuContent } from '../WITDropmenu';
import PropTypes from 'prop-types';

class WITPanelTab extends React.Component {
    constructor(props) {
        super(props);
        this.assignStates();
    }
    static propTypes = {
        mainTitle: PropTypes.string,
        uniqueRef: PropTypes.string,
        tabs: PropTypes.array,
        onChange: PropTypes.func,
        moreTabs: PropTypes.object
    }
    static defaultProps = {
        uniqueRef: '',
        mainTitle: '',
        moreTabs: {
            limit: -1,
            icon: 'fa fa-chevron-circle-down'
        }
    }
    assignStates() {
        let { tabs, mainTitle } = this.props;
        this.state = { tabs, mainTitle };
    }
    componentWillReceiveProps({ tabs, mainTitle, moreTabs }) {
        let prevTabs = this.state.tabs;
        let prevMainTitle = this.state.mainTitle;
        let prevMoreTabs = this.state.moreTabs;

        var newState = {};

        if (prevTabs !== tabs) {
            newState.tabs = tabs;
        }
        if (prevMainTitle !== mainTitle) {
            newState.mainTitle = mainTitle;
        }
        if (prevMoreTabs !== moreTabs) {
            newState.moreTabs = moreTabs;
        }

        this.setState(newState);
    }
    onChange(e, item) {
        let tabs = this.state.tabs || [];
        tabs.map((obj, index) => {
            obj.isactive = false;
        });
        tabs.first(p => p.key == item.key).isactive = true;
        if (this.props.onChange != null && this.props.onChange != undefined) {
            this.props.onChange(e, item);
        }
        this.setState({ tabs });
    }
    renderTabMenus() {

        let tabs = this.state.tabs || [];
        let mainTabSource = tabs;
        let moreInfo = 0;
        if (this.props.moreTabs)
            moreInfo = this.props.moreTabs.limit || 0;

        if (moreInfo > 0) {
            mainTabSource = tabs.map((item, i) => {
                if (i <= (moreInfo - 1)) {
                    return item;
                }
            });
        }
        let tabMenus = mainTabSource.map((item, i) => {
            if (item !== undefined) {
                let tabStyle = Object.assign({}, {
                    borderBottom: '1px solid #3cafda'
                }, item.tabStyle);
                let linkStyle = Object.assign({}, {
                    fontSize: '12px',
                    fontWeight: 'bold'
                }, item.linkStyle);
                let href = `#`;
                let clsInfo = (item.isactive ? 'active' : '');
                return <li onClick={(e) => this.onChange(e, item)} className={clsInfo} style={tabStyle}>
                    <a href={href} data-toggle="tab" style={linkStyle} aria-expanded="true">{item.caption}</a>
                </li>
            }
        });
        if (moreInfo > 0) {
            let itemStyle = {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                minWidth: '0'
            };

            let moreLink = <li className="wit-no-hide" >
                <a className="wit-no-hide" href="#" data-toggle="tab" aria-expanded="true">
                    <DropMenu bsClass="pull-right" bgMode={false} bsStyle={{ padding: '0', margin: '0', height: '20px', backgroundColor: 'transparent', marginTop: '-5px' }} visibleAlign="right" menuWidth="200px">
                        <DropMenuContent>
                            <span className="wit-no-hide"><i className="fa fa-chevron-circle-down wit-no-hide"></i></span>
                        </DropMenuContent>
                        <ul >
                            {
                                tabs.map((item, i) => {
                                    if (item !== undefined && i > (moreInfo - 1)) {
                                        let activeClass = (item.isactive ? 'bg-gray' : '');
                                        return <li style={itemStyle} className={activeClass} onClick={(e) => this.onChange(e, item)} >{item.caption}</li>;
                                    }
                                })
                            }
                        </ul>
                    </DropMenu>
                </a>
            </li>;
            tabMenus.push(moreLink);
        }
        return tabMenus;
    }
    renderTabContent() {
        let tabs = this.state.tabs || [];
        let tabContents = tabs.map((item, i) => {
            let containerStyle = Object.assign({}, item.containerStyle);
            let id = `#${item.key}`;
            let clsInfo = (item.isactive === true ? 'tab-pane active' : 'tab-pane');
            return <div id={id} className={clsInfo} style={containerStyle}>
                {item.content}
            </div>
        })
        return tabContents;
    }

    render() {
        let { mainTitle } = this.state;
        let style = {};
        if (this.props.isLeftAlign === true)
            style = { left: 0 };
        return (<div className="panel panel-dark-light">
            <div className="panel-heading">
                <span className="panel-title">{mainTitle}</span>
                <ul className="nav panel-tabs" style={style}>
                    {
                        this.renderTabMenus()
                    }
                </ul>
            </div>
            <div className="panel-body">
                <div className="tab-content">
                    {
                        this.renderTabContent()
                    }
                </div>
            </div>
        </div>
        );
    }
}
export { WITPanelTab }