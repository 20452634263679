import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
//import './WITWindow.css';
import Draggable from 'react-draggable';
import * as cntrl from '../AsyncLoader';
import * as witEnum from '../WITEnum';
import $ from 'jquery';

export class WITWindow extends Component {
        static propTypes = {
                id: PropTypes.string.isRequired,
                bounds: PropTypes.shape({ left: PropTypes.number, top: PropTypes.number, right: PropTypes.number, bottom: PropTypes.number }),
                headerIcons: PropTypes.arrayOf(PropTypes.string),
                title: PropTypes.string.isRequired,
                url: PropTypes.string,
                data: PropTypes.object,
                context: PropTypes.object,
                isModal: PropTypes.bool,
                isClosable: PropTypes.bool,
                showloading: PropTypes.bool,
                onClose: PropTypes.func,
                windowClass : PropTypes.string
        };
        static defaultProps = {
                bounds: undefined, isModal: false
        };

        constructor(props) {
                super(props);
                this.childRef = null;
                this.state = { isLoaded: false, bounds: { left: 0, top: 0, right: 1220 - 900 - 20, bottom: 863 - 20 - 500 } };
                this.onClosing = this.onClosing.bind(this);
        }

        focus() {
                if (this.childRef && typeof this.childRef.setFocus === 'function') {
                        this.childRef.focus();
                }
        }
        setPostion(e) {
                let { isLoaded } = this.state;
                if (this.childRef && isLoaded === false) {
                        var node = ReactDOM.findDOMNode(e);
                        if (node) {
                                var cntainer = $(node).closest('.wit-container')
                                let parentBounds = cntainer.get(0).getBoundingClientRect();
                                let areaBounds = node.getBoundingClientRect();
                                let width = parentBounds.width - (areaBounds.width + 0);
                                let height = parentBounds.height - (areaBounds.height + 0);
                                let bounds = { left: 0, top: 0, right: width, bottom: height };
                                const restrictedBounds = this.props.bounds;
                                if (restrictedBounds) {
                                        if (restrictedBounds.left)
                                                bounds.left = restrictedBounds.left;
                                        if (restrictedBounds.top)
                                                bounds.top = restrictedBounds.top;
                                }

                                isLoaded = true;
                                this.setState({ bounds, isLoaded });
                        }
                }
        }

        renderLoading() {
                let inputAttr = { zIndex: witEnum.WITZIndexes.LOADING, display: 'grid', justifyContent: 'center', alignItems: 'center', alignContent: 'center' };
                //, width: '-webkit-fill-available', height: '-webkit-fill-available' 
                return (<div className="witcontainer-loading" style={inputAttr}>
                        <div style={{ width: '100px', height: '100px' }}>
                                <img src="" alt="" ></img>
                        </div>
                </div>);
        };

        onWindowDynamicLoaded(e) {
                if (e)
                        window.setTimeout(() => { this.setPostion(e); }, 500);
        }
        onDynamicLoaded(e) {
                this.childRef = e;
        }

        onClosing() {
                const { onClose } = this.props;
                if (onClose)
                        onClose({ component: this.childRef, id: this.props.id });
        }
        renderWindow(isModal) {
                const { id, url, data, context } = this.props;
                let loaderProps = { id, url, data, context };

                let { title } = this.props;

                let classNames = 'window-container inline-window mb-5  rounded window-zindex';
                if (isModal === true)
                        classNames = 'window-container shadow-sm mb-5  rounded window-zindex';
                   
                        if(this.props.windowClass)
                        classNames+= ' ' + this.props.windowClass;

                let windowBody = this.props.children;
                if (this.props.children === undefined)
                        windowBody = (<cntrl.AsyncLoader childRef={(e) => this.onDynamicLoaded(e)} {...loaderProps} />);
                else {
                       
                        windowBody = this.props.children;
                        this.onDynamicLoaded(windowBody);
                }

                return (<div className={classNames}>
                        <div className="window-header">
                                <span className="title">{title}</span>
                                <button onClick={this.onClosing} type="button" className="close" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div className="window-body">
                                {windowBody}
                                {this.props.showloading && this.renderLoading()}
                        </div>
                </div>);
        };
        renderModalWindow(isModal) {
                return (<div className="window-modal-backdrop window-backdrop-zindex">
                        {this.renderWindow(isModal)}
                </div>);
        }
        renderModalWindowEx(isModal) {
                let { bounds } = this.state;
                const restrictedBounds = this.props.bounds;
                if (restrictedBounds) {
                        if (restrictedBounds.left)
                                bounds.left = (restrictedBounds.left + 15);
                        if (restrictedBounds.top)
                                bounds.top = restrictedBounds.top;
                }

                return (<div className="window-modal-backdrop">
                        <Draggable
                                axis="both"
                                handle=".window-header"
                                defaultPosition={{ x: bounds.left, y: 0 }}
                                position={null}
                                bounds={bounds}
                                scale={1}
                        >
                                {this.renderWindow(isModal)}
                        </Draggable>
                </div>);
        };

        render() {
                let isModal = this.props.isModal || false;
                return (isModal === true) ? this.renderModalWindow(isModal) : this.renderWindow(isModal);
        }
}