import React from 'react';
import PropTypes from 'prop-types';
import * as hlpr from '../WITUtils';

export class WITButtonWrapper extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        onWitClick: PropTypes.func.isRequired,
        formID: PropTypes.string.isRequired,
        propertyID: PropTypes.any.isRequired,
        owner: PropTypes.any.isRequired
    };

    invokeClick(evt) {
        if (this.props.owner != null) {
            hlpr.Utils.invoke({
                owner: this.props.owner,
                propertyID: this.props.propertyID,
                controlID: this.props.id,
                callback: (e) => {
                    this.props.onWitClick({
                        userID: e.userID,
                        event: evt,
                        id: this.props.id,
                        eventName: 'CLICK'
                    });
                }
            });

        }
        else {
            this.props.onWitClick({
                event: evt,
                id: this.props.id,
                eventName: 'CLICK'
            });
        }
    }
    onClickEvent = (evt) => {
        evt.preventDefault();
        if (this.props.onWitClick) {
            this.invokeClick(evt);
        }
    };

    renderChildrens() {
        const children = this.props.children;
        return React.Children.map(children, (child, index) =>
            React.cloneElement(child, { key: index, onClick: this.onClickEvent }));
    };
    render() {
        return this.renderChildrens();

    }
}