import moment from 'moment';
import $ from "jquery";

export class Utils {
    static ConfigInfo = null;
    static ConfigWaitList = [];
    static AppInfo = null;
    static DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    static MONTHS_EX = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    static MONTHS = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    static CHAR_CODE_ZERO = "0".charCodeAt(0);
    static CHAR_CODE_NINE = "9".charCodeAt(0);
    static CHAR_CODE_a = "a".charCodeAt(0);
    static CHAR_CODE_z = "z".charCodeAt(0);
    static CHAR_CODE_A = "A".charCodeAt(0);
    static CHAR_CODE_Z = "Z".charCodeAt(0);
    static LISTOFDAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    static TransactionAuthenicationInfoList = [];
    static Functions = [];
    static Errors = [];
    static ___uniqueID = 0;

    static getUniqueID() {
        return `XPMS_${Utils.___uniqueID++}`;
    }
    static escapeHtml(htmlText) {
        return $("<div />").text(htmlText).html();
    };
    static isPrivateBrowser() {
        return new Promise((resolve) => {
            const on = () => resolve(true); // is in private mode
            const off = () => resolve(false); // not private mode
            const testLocalStorage = () => {
                try {
                    if (localStorage.length) off();
                    else {
                        localStorage.x = 1;
                        localStorage.removeItem('x');
                        off();
                    }
                } catch (e) {
                    // Safari only enables cookie in private mode
                    // if cookie is disabled then all client side storage is disabled
                    // if all client side storage is disabled, then there is no point
                    // in using private mode
                    navigator.cookieEnabled ? on() : off();
                }
            };
            // Chrome & Opera
            if (window.webkitRequestFileSystem) {
                return void window.webkitRequestFileSystem(0, 0, off, on);
            }
            // Firefox
            if ('MozAppearance' in document.documentElement.style) {
                const db = indexedDB.open('test');
                db.onerror = on;
                db.onsuccess = off;
                return void 0;
            }
            // Safari
            if (/constructor/i.test(window.HTMLElement)) {
                return testLocalStorage();
            }
            // IE10+ & Edge
            if (!window.indexedDB && (window.PointerEvent || window.MSPointerEvent)) {
                return on();
            }
            // others
            return off();
        });
    }
    static isValidCreditCard(cardNumber) {
        var luhnChk = (function (arr) {
            return function (ccNum) {
                var
                    len = ccNum.length,
                    bit = 1,
                    sum = 0,
                    val;

                while (len) {
                    val = parseInt(ccNum.charAt(--len), 10);
                    sum += (bit ^= 1) ? arr[val] : val;
                }

                return sum && sum % 10 === 0;
            };
        }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

        return luhnChk(cardNumber);
    }
    //static elasticsearch() {
    //    //var settings = {
    //    //    "async": true,
    //    //    "crossDomain": true,
    //    //    "url": "http://10.0.5.33:9200/eps_index/epi_addr_mast/_search",
    //    //    "method": "GET",
    //    //    "headers": {
    //    //        "cache-control": "no-cache",
    //    //        "postman-token": "eeb80b43-2522-a366-5e08-bd0edf99f26f"
    //    //    }
    //    //}

    //    //$.ajax(settings).done(function (response) {
    //    //    console.log(response);
    //    //});

    //    const inputs = {
    //        method: 'GET',
    //        headers: {
    //            //'Content-Type': 'application/x-www-form-urlencoded',
    //            'Accept': 'application/json;charset=utf8'
    //        }
    //    }
    //    fetch("http://10.0.5.33:9200/eps_index/epi_addr_mast/_search", inputs)
    //        .then(res => res.json())
    //        .then(
    //        (result) => {
    //            console.log(result);
    //        },
    //        (error) => {
    //            console.log(error);
    //        }
    //        );
    //    return;
    //    var client = new elasticsearch.Client({
    //        host: '10.0.5.33:9200',
    //        host: {
    //            protocol: 'http',
    //            host: '10.0.5.33',
    //            port: 9200,
    //            method: 'GET',
    //            headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type' :'application/json'}
    //        }
    //    });

    //    client.search({
    //        index: 'eps_index',
    //        type: 'epi_addr_mast',
    //        body: {
    //            query: {
    //                match: {
    //                    body: 'Test'
    //                }
    //            }
    //        }
    //    }).then(function (resp) {
    //        var hits = resp.hits.hits;
    //        console.log(hits); 
    //    }, function (err) {

    //        console.log(err.message);
    //    });
    //}
    static randamNo()
    {
        var min=14; 
        var max=15;  
        return Math.random() * (+max - +min) + +min; 
    }
    static getMessage(errNo, msg) {
        if (errNo > 0) {
            var err = Utils.Errors.first(s => s.ID === errNo);
            if (err != null)
                return err.Text;
        }
        return "";
    };
    static replaceAll(text, search, replacement) {
        return text.replace(new RegExp(search, 'g'), replacement);
    }
    static isNullOrEmpty(v) {
        return (v === undefined || v === null || $.trim(v) === "");
    };
    static getValidDate(v) {
        if (this.isNullOrEmpty(v) || new Date(v) < new Date("2 3 1900"))
            return undefined;
        else {
            return new Date(v);
        }
    };
    static getValidDateString(v) {
        if (v instanceof String || typeof (v) == "string")
            v = this.getValidDate(v);

        if (this.isNullOrEmpty(v))
            return undefined;
        else
            return this.dateFormat(v, 'dd MMM yyyy');
    };
    static dateFormat(date, f = 'dd MMM yyyy') {
        if (!date.valueOf()) return '';
        var d = date;
        return f.replace(/(yyyy|yy|mmmm|mmm|mm|dddd|ddd|dd|hh|nn|ss|tt)/gi,
            function (v) {
                const h = d.getHours();
                //switch (v.toLowerCase()) {
                switch (v) {
                    case 'yyyy': return d.getFullYear();
                    case 'yy': return d.getFullYear().toString().substr(2, 2);
                    case 'MMMM': return Utils.MONTHS_EX[d.getMonth()];
                    case 'MMM': return Utils.MONTHS_EX[d.getMonth()].substr(0, 3);
                    case 'MM': return (d.getMonth() + 1).toString().padStart(2, '0');
                    case 'DDDD': return Utils.DAYS[d.getDay()];
                    case 'DDD': return Utils.DAYS[d.getDay()].substr(0, 3);
                    case 'dd': return d.getDate().toString().padStart(2, '0');
                    case 'hh': return ((h % 12) ? h : 12).toString().padStart(2, '0');
                    case 'HH': return h.toString().padStart(2, '0');
                    case 'mm': return d.getMinutes().toString().padStart(2, '0');
                    case 'ss': return d.getSeconds().toString().padStart(2, '0');
                    case 'tt': return d.getHours() < 12 ? 'AM' : 'PM';
                    default:
                        break;
                }
            }
        );
    }
    static isAlphabet(n) {
        return (n >= Utils.CHAR_CODE_a && n <= Utils.CHAR_CODE_z) || (n >= Utils.CHAR_CODE_A && n <= Utils.CHAR_CODE_Z);
    }
    static isDigitCode(n) {
        return (n >= Utils.CHAR_CODE_ZERO && n <= Utils.CHAR_CODE_NINE);
    }
    static dateParse(inputText) {
        let valid = true;
        let dt = null;
        const today = new Date();
        inputText = $.trim(inputText);
        if (inputText.length !== 0) {
            let day = null;
            let month = null;
            let year = null;
            var dataList = inputText.split('/');
            if (dataList.length <= 1)
                dataList = inputText.split('-');
            if (dataList.length > 1) {
                day = parseInt(dataList[0]);
                month = parseInt(dataList[1]);
                if (dataList.length > 2)
                    year = parseInt(dataList[2]);
            }
            else {
                inputText = inputText.replace('.', '').replace('/', '').replace('-', '');
                var digitText = "";
                var charText = "";
                for (var i = 0; i < inputText.length; i++) {
                    const c = inputText[i];
                    if (Utils.isDigitCode(c.charCodeAt(0)))
                        digitText += c;
                    else {
                        if (Utils.isAlphabet(c.charCodeAt(0)))
                            charText += c;
                        if (digitText.length < 2)
                            digitText = "0" + digitText;
                    }
                }
                if (charText.length > 0) {

                    if (digitText.length <= 2)
                        day = parseInt(digitText);
                    else
                        day = parseInt(digitText.substr(0, 2));

                    charText = charText.toLowerCase();
                    let mnth = Utils.MONTHS.first(m => m.startsWith(charText));
                    if (mnth != null) {
                        const index = Utils.MONTHS.indexOf(mnth);
                        month = index + 1;
                    }

                    if (digitText.length > 2)
                        year = parseInt(digitText.substr(2));

                }
                else {
                    if (digitText.length <= 2)
                        day = parseInt(digitText);
                    else
                        day = parseInt(digitText.substr(0, 2));

                    if (digitText.length > 2) {
                        month = parseInt(digitText.length > 3 ? digitText.substr(2, 2) : digitText.substr(2));
                    }

                    if (digitText.length > 4)
                        year = parseInt(digitText.substr(4));
                }
            }

            if (year != null && year.toString().length === 2) {
                year = 2000 + year;
            }

            if (month != null) {
                if (month < 1 || month > 12)
                    month = null;
            }
            if (month == null)
                month = today.getMonth() + 1;

            if (year == null)
                year = today.getFullYear();

            if (month === 1 || month > 2) {
                if (day > Utils.LISTOFDAYS[month - 1]) {
                    valid = false;
                }
            }
            if (month === 2) {
                let isLeapYear = false;
                if ((!(year % 4) && year % 100) || !(year % 400)) {
                    isLeapYear = true;
                }
                if (isLeapYear && (day > 29)) {
                    valid = false;
                }
                else if (!isLeapYear && (day >= 29)) {
                    valid = false;
                }
            }
            if (valid) {
                dt = new Date(year, (month - 1), day);
            }
        }
        return dt;
    };
    static isEmail(text) {
        //const reg = new RegExp('^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$');
        //const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm;
        return reg.test(text);
    }
    static isPhone(text) {
        const reg = new RegExp('^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$');
        return reg.test(text);
    }
    static isIPAddressPhone(text) {
        const reg = new RegExp('^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
        return reg.test(text);
    }
    static setUserInfo(userID, userName, client, property) {
        Utils.isPrivateBrowser().then((isPrivate) => {
        });
        //Utils.elasticsearch();
        Utils.AppInfo = {};
        Utils.AppInfo.UserID = userID;
        Utils.AppInfo.UserName = userName;
        Utils.AppInfo.Property = property;
        Utils.AppInfo.Client = client;
    }
    static creditCardDate(dateText, isFrom) {
        var ccDate = null;
        isFrom = isFrom || false;
        if (dateText.length >= 3) {
            let len = dateText.length === 3 ? 1 : 2;
            var mnth = +(dateText.substring(0, len));
            if (mnth < 1 || mnth > 12) {
                return null;
            }

            var yr = +(dateText.substring(len));
            if (yr < 2000)
                yr += 2000;

            dateText = `01 ${Utils.MONTHS_EX[mnth - 1].substring(0, 3)} ${yr}`;
            var mDt = moment(dateText);
            if (isFrom === false) {
                mDt = mDt.endOf('month');
            }
            ccDate = mDt.toDate();
        }
        return ccDate;
    }

    static canDoAction(propertyID, operationCodes, operationType) {
        let mustAuthenicate = false;
        let transactionList = [];
        var property = Utils.TransactionAuthenicationInfoList.first(p => p.PropertyCode === propertyID);
        if (property != null)
            transactionList = property.TransactionCodes;

        if (transactionList != null && transactionList.length > 0 && operationCodes.length > 0) {
            for (var i = 0; i < operationCodes.length; i++) {
                const operationCode = operationCodes[i];
                if (transactionList.any(t => t.ID === operationCode.ID && t.ApplicableTypes.any(a => a === operationType))) {
                    mustAuthenicate = true;
                    break;
                }
            }
        }
        return mustAuthenicate;
    }
    static authenticationReqiured(propertyID, formID, controlID) {
        try {
            var property = null;
            if (Utils.isNullOrEmpty(propertyID))
                propertyID = "CRS";

            var moduleFunction = Utils.Functions.first(f => f.ID === formID);
            if (moduleFunction != null && moduleFunction.Controls.length > 0) {
                var cntrl = moduleFunction.Controls.first(c => c.ID === controlID);
                if (cntrl != null) {
                    if (cntrl.CanClientAccess) {
                        property = cntrl.PropertyList.first(p => p.Code === propertyID);
                        if (property == null || property.IsAuthenicationRequired) {
                            return true;
                        }
                        else
                            return false;
                    }
                    else
                        return true;
                }
                else
                    return false;
            }
        }
        catch (ex) { }
        return false;
    }
    static showAuthenticate(options) {
        const wndwOptions = {};
        wndwOptions.title = 'Authenication';
        wndwOptions.isWITUrl = true;
        wndwOptions.isModal = true;
        wndwOptions.url = 'WITAuthenication';
        wndwOptions.style = { height: 'auto', width: 'auto' };
        wndwOptions.data = {};
        wndwOptions.data.propertyID = options.propertyID;
        wndwOptions.data.formID = options.formID;
        wndwOptions.data.controlID = options.controlID;
        wndwOptions.onClose = (e) => {
            if (e.data.Isvalid || false) {
                const args = {};
                args.userID = args.UserID;
                options.callback(args);
            }
        };
        options.owner.showWindow(wndwOptions);
    }
    static invoke(options) {
        let mustAuthenicate = options.mustAuthenicate || false;

        if (!mustAuthenicate && options.operationCodes && options.operationCodes.length > 0)
            mustAuthenicate = Utils.canDoAction(options.propertyID, options.operationCodes, options.operationType);

        const isvalid = !(Utils.isNullOrEmpty(options.formID) ||
            Utils.isNullOrEmpty(options.controlID) ||
            Utils.isNullOrEmpty(options.propertyID));

        if (mustAuthenicate || (isvalid && Utils.authenticationReqiured(options.propertyID, options.formID, options.controlID)))
            Utils.showAuthenticate(options);
        else {
            //Utils.AppInfo.UserID
            options.callback({ userID: Utils.AppInfo.UserID });
        }
    }

    static search(options) {
        return new Promise((resolve, reject) => {
            Utils.ajax(options, resolve);
        });
    }
    static getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    static storgeData(key, value) {
        if (arguments.length === 1)
            return localStorage.getItem(key);
        else
            localStorage.setItem(key, value);
    }
    static openFileInWindow(options, callback) {
        let { url, data, files } = options;
        data = data || null;

        const config = Utils.getAjaxSettings(data, files, true);
        config.headers.Authorization = 'Bearer ' + Utils.storgeData('jwtToken');
        config.headers["X-CSRF-TOKEN"] = Utils.getCookie('XSRF-TOKEN');
        config.responseType = 'arraybuffer';
        config.responseType = 'blob';

        let baseUrl = Utils.ConfigInfo.ServiceUrl;
        let apiUrl = `${baseUrl}/${url}`;
        //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
        fetch(apiUrl, config)
            .then(res => {
                if (res.status === 401 && res.headers.has('Token-Expired')) {
                    throw new Error('EXPIRED');
                }
                else {
                    return res.blob();
                }
            })
            .then((blobby) => {
                try {
                    callback();
                    let objectUrl = window.URL.createObjectURL(blobby);
                    window.open(objectUrl, "_blank", 'fullscreen=yes');
                    window.URL.revokeObjectURL(objectUrl);
                }
                catch (ex) { }
            })
            .catch((er) => {
                if (er && er.message === 'EXPIRED') {
                    let opts = { options: options, callback };
                    Utils.refreshToken(opts, true);
                    return;
                }
            });
    }
    static downloadFile(options, callback) {

        let { url, data, files } = options;
        data = data || null;

        const config = Utils.getAjaxSettings(data, files, true);
        config.headers.Authorization = 'Bearer ' + Utils.storgeData('jwtToken');
        config.headers["X-CSRF-TOKEN"] = Utils.getCookie('XSRF-TOKEN');
        config.responseType = 'arraybuffer';
        config.responseType = 'blob';

        const isAttachment = options.isAttachment || false;

        let anchor = null;
        let downfileName = 'no-file-name';
        if (isAttachment === true) {
            anchor = document.createElement("a");
            document.body.appendChild(anchor);

            if (!Utils.isNullOrEmpty(options.fileName))
                downfileName = options.fileName;
        }

        let baseUrl = Utils.ConfigInfo.ServiceUrl;
        let apiUrl = `${baseUrl}/${url}`;
        //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
        fetch(apiUrl, config)
            .then(res => {
                if (res.status === 401 && res.headers.has('Token-Expired')) {
                    throw new Error('EXPIRED');
                }
                else {
                    if (isAttachment === true) {
                        if (res.headers) {
                            var disposition = res.headers.get('Content-Disposition');
                            if (disposition && disposition.indexOf('attachment') !== -1) {
                                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                var matches = filenameRegex.exec(disposition);
                                if (matches != null && matches[1]) {
                                    var fileName = matches[1].replace(/['"]/g, '');
                                    if (!Utils.isNullOrEmpty(fileName))
                                        downfileName = fileName;
                                }
                            }
                        }
                    }
                    return res.blob();
                }
            })
            .then((blobby) => {
                try {
                    callback({downloaded: true});

                    if (isAttachment === true) {
                        let objectUrl = window.URL.createObjectURL(blobby);
                        anchor.href = objectUrl;
                        anchor.download = downfileName;
                        anchor.click();
                        window.URL.revokeObjectURL(objectUrl);
                    }
                    else {
                        //var file = new Blob([blobby], {type: 'application/pdf'});
                        var file = blobby;
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL, "_blank", 'fullscreen=yes');
                    }
                }
                catch (ex) { }
            })
            .catch((er) => {
                if (er && er.message === 'EXPIRED') {
                    let opts = { options: options, callback };
                    Utils.refreshToken(opts, true);
                    return;
                }
                callback({downloaded: false});
            });
    }
    static getAjaxSettings(data, files, isFile = false) {
        let config = {};
        config.method = 'POST';
        config.credentials = 'include';
        config.crossDomain = true;
        //if (data && typeof (data) === "object") {
        config.processData = false;
        config.contentType = false;
        config.mimeType = "multipart/form-data";
        var formData = new FormData();
        if (data !== undefined && data !== null)
            formData.append("Parameters", JSON.stringify(data));
        files = files || null
        if (files && files.length > 0)
            formData.append("Files", files);
        // for (const [key, value] of Object.entries(data)) {
        //     // if (typeof (value) === "object")
        //     //     formData.append(key, JSON.stringify(value));
        //     // else
        //         formData.append(key, value);
        // }

        if (isFile === false) {
            config.headers = {
                'Accept': 'application/json;charset=utf8'
            };
        }
        else {
            config.headers = {};
        }
        config.body = formData;
        /* }
         else {
             config.headers = {
                 'Content-Type': 'application/json',
                 'Accept': 'application/json;charset=utf8'
             };
             config.body = data;
         }*/
        return config;
    }
    static loginAjax(data, callback) {
        let baseUrl = Utils.ConfigInfo.ServiceUrl;
        let apiUrl = `${baseUrl}/auth/signin`;
        const config = Utils.getAjaxSettings(data);
        fetch(apiUrl, config)
            .then(res => {
                if (res.ok) {
                    if (res.headers.get("content-type").indexOf("application/json") !== -1)
                        return res.json();
                    else
                        return res.text();
                }
            })
            .then((r) => {
                const access_token = r.access_token || r.AccessToken;
                const refresh_token = r.refresh_token || r.RefreshToken;
                Utils.storgeData('jwtToken', access_token);
                Utils.storgeData('refreshToken', refresh_token);
                callback(r);
            }, (er) => { callback(null); });
    }
    static ajax(options, callback) {
        if (Utils.ConfigInfo == null) {
            Utils.ConfigWaitList.push({ IsLogin: false, options: options, callback: callback });
            return;
        }

        let { url, data, files } = options;
        data = data || null;
        callback = callback || null;

        const config = Utils.getAjaxSettings(data, files);
        config.headers.Authorization = 'Bearer ' + Utils.storgeData('jwtToken');
        config.headers["X-CSRF-TOKEN"] = Utils.getCookie('XSRF-TOKEN');

        let baseUrl = Utils.ConfigInfo.ServiceUrl;
        let apiUrl = `${baseUrl}/${url}`;

        //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
        fetch(apiUrl, config)
            .then(res => {
                if (res.status === 401 && res.headers.has('Token-Expired')) {
                    throw new Error('EXPIRED');
                }
                else {
                    if (res.headers && res.headers.get("content-type") && res.headers.get("content-type").indexOf("application/json") !== -1)
                        return res.json();
                    else
                        return res.text();
                }
            })
            .then((r) => {
                callback(r);
            })
            .catch((er) => {
                if (er && er.message === 'EXPIRED') {
                    let opts = { options: options, callback };
                    Utils.refreshToken(opts);
                    return;
                }
                else
                    callback(null);
            });
    }
    static refreshToken(opts, isFile = false) {
        let baseUrl = Utils.ConfigInfo.ServiceUrl;
        let apiUrl = `${baseUrl}/auth/refreshtoken`;
        const config = Utils.getAjaxSettings({ RefreshToken: Utils.storgeData('refreshToken') });
        config.headers.Authorization = 'Bearer ' + Utils.storgeData('jwtToken');
        config.headers["X-CSRF-TOKEN"] = Utils.getCookie('XSRF-TOKEN');
        fetch(apiUrl, config)
            .then(res => {
                if (res.ok) { return res.json(); } throw new Error('Not Ok');
            })
            .then((r) => {
                const access_token = r.access_token || r.AccessToken;
                const refresh_token = r.refresh_token || r.RefreshToken;
                Utils.storgeData('jwtToken', access_token);
                Utils.storgeData('refreshToken', refresh_token);
                if (isFile === true)
                    Utils.downloadFile(opts.options, opts.callback);
                else
                    Utils.ajax(opts.options, opts.callback);
            }, (er) => {
                console.log('There has been a problem with your fetch operation: ', er);
            });

    }
    static loadConfig() {
        fetch("xpmsconfig.json")
            .then(res => res.json())
            .then(
                (result) => {
                    Utils.ConfigInfo = result;
                    for (var i = 0; i < Utils.ConfigWaitList.length; i++) {
                        if (Utils.ConfigWaitList[i].IsLogin) {
                            Utils.loginAjax(Utils.ConfigWaitList[i].options, Utils.ConfigWaitList[i].callback);
                        }
                        else {
                            Utils.ajax(Utils.ConfigWaitList[i].options, Utils.ConfigWaitList[i].callback);
                        }
                    }
                },
                (error) => {
                }
            );
    }
}