export const WITTextboxTypes = {
    textbox: 'textbox',
    alphaNumeric: 'alphaNumeric',
    numeric: 'numeric',
    time: 'time',
    time24hr: 'time24hr',
    titleCase: 'titleCase',
    sentenceCase: 'sentenceCase',
    password: 'password'
}
export const WITNumericTypes = {
    negative: 'negative',
    postive: 'postive',
    both: 'both'
}
export const WITMessageboxTypes = {
    error: 'error',
    info: 'info',
    question: 'question',
    warning: 'warning',
    none: 'none'
}

export const LocationEnums={
    left:'left-bottom',
    leftTop:'left-top',
    right:'right-bottom',
    rightTop:'right-top',
    bottom:'bottom',
    top:'top'
}
export const WITContainerTypes = {
    window: 'window',
    tab: 'tab',
    normal: 'normal'
}
export const WITZIndexes={
    POPUP:107,
    LOADING:106,
    ACTIVE:105,
    MASK:104,
    INACTIVE:100
}
export const KeyCodes = {
    esc:   27,
    space: 32,
    enter: 13,
    tab:   9,
    up:    38,
    down:  40,
    home:  36,
    end:   35,
    n:     78,
    p:     80
  };
  export const FocusableElements = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type=hidden])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'object',
    'embed',
    '[tabindex]:not(.modal)',
    'audio[controls]',
    'video[controls]',
    '[contenteditable]:not([contenteditable="false"])',
  ];