import { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

export default class WITBase extends Component {
    constructor(props, vm) {
        super(props);
        this._________lastFocus = null;
        this.VM = vm;
        this.VM.setProps(props);
        this.VM.Register(this);
    }
    componentWillUnmount() {
        try {
            var cmpnt = ReactDOM.findDOMNode(this);
            if (cmpnt) {
                var container = $(cmpnt);
                if (container) {
                    container.off('focusin', ':radio:not([disabled]) :checkbox:not([disabled]) a:not([disabled]), button:not([disabled]), :input:not([disabled]), [tabindex]', null, this.onElementFocus);
                }
            }
        }
        catch{ }
        if (this.VM) {
            this.VM.Unregister();
            this.VM = null;
        }
    }
    componentDidMount() {
        let elmt = null;
        let container = null;
        try {
            var cmpnt = ReactDOM.findDOMNode(this);
            if (cmpnt) {
                container = $(cmpnt);
                if (container) {
                    elmt = container.find('.window-body').find(':radio:not([disabled]) :checkbox:not([disabled]) a:not([disabled]), button:not([disabled]), :input:not([disabled]), [tabindex]').first();
                    container.on('focusin', ':radio:not([disabled]) :checkbox:not([disabled]) a:not([disabled]), button:not([disabled]), :input:not([disabled]), [tabindex]', null, this.onElementFocus);
                }
            }
        }
        catch{ }
        if (this.VM && this.VM.________isloaded === false) {
            this.VM.________isloaded = true;
            //if (elmt.length === 0 && container)
            elmt = container;
            if (elmt.length > 0) {
                this.focusElement(elmt[0]);
            }

            if (this.onLoad)
                this.onLoad();
        }
    }
    onClosing = (e) => {
        const { context } = this.props;
        if (context && this.VM) {
            if (context.type === 'tab')
                this.VM.closeTab();
            else if (context.type === 'tabbedWindow')
                this.VM.closeTab();
            else if (context.type === 'window')
                this.VM.close();
        }
    };
    onActive = (e) => {
        try {
            var cmpnt = ReactDOM.findDOMNode(this);
            if (cmpnt) {
                var container = $(cmpnt);
                if (this._________lastFocus) {
                    if (container) {
                        let elemnts = container.find(`[name=${this._________lastFocus}],[id=${this._________lastFocus}]`);
                        if (elemnts.length === 0 && container)
                            elemnts = container;
                        if (elemnts.length > 0) {
                            this.focusElement(elemnts[0]);
                        }
                    }
                }
                else {
                    if (container.length > 0) {
                        let elmnt = $(container[0]);
                        if (!elmnt.hasClass('wit-container')) {
                            container = elmnt.closest('.wit-container');
                            if (container.length > 0)
                                elmnt = $(container[0]);
                            else
                                return;
                        }
                        this.focusElement(elmnt);
                    }
                }
            }
            // if (this.onActive)
            // this.onActive();
        } catch (ex) { }
    };
    onInactive = (e) => {
        try {
            // if (this.onInactive)
            // this.onInactive();
        }
        catch (ex) { }
    };

    focusElement(elnt) {
        if (elnt) {
            if ($(elnt).hasClass('wit-dd-wrapper') || (elnt.className && elnt.className.includes('wit-dd-wraper'))) {
                var re = this.findReactElement(elnt);
                if (re) {
                    window.setTimeout(() => {
                        if (re.focus) {
                            re.focus();
                        }
                    }, 100);
                }
            }
            else {
                window.setTimeout(() => {
                    if (elnt.focus)
                        elnt.focus();
                    if (elnt.select)
                        elnt.select();
                });
            }
        }
    }
    onElementFocus = (e) => {
        try {
            if (e && e.target) {
                if (e.target.name || e.target.id)
                    this._________lastFocus = (e.target.id || e.target.name);
                else {
                    const elmnt = $(e.target);
                    if (elmnt.hasClass('wit-dd-item-wrapper')) {
                        var prnt = elmnt.closest('wit-dd-wrapper');
                        if (prnt.length !== 0) {
                            this._________lastFocus = prnt[0].id;
                        }
                    }
                }
            }
        }
        catch{ }
    }
    findReactElement(node) {
        for (var key in node) {
            if (key.startsWith("__reactInternalInstance$")) {
                return node[key]._debugOwner.stateNode;
            }
        }
        return null;
    }
}