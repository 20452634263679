import VMBase from '../VMBase';
import * as cntrl from '../../wit-components';

export default class AppContainerVM extends VMBase {
    constructor(props) {
        super(props);
        this.init();
    }
    init() {
        const model = this.Data;
        if (Object.keys(model).length !== 0)
            return;

        model.showloading = false
        model.Windows = [];
        model.containerOptions = null;
    }
    setActiveContainer() {
        let { Windows } = this.Data;
        Windows.forEach((wndw) => {
            wndw.isActive = false;
        });
        if (Windows.length > 0) {
            Windows[Windows.length - 1].isActive = true;
        }
        else {
            if (this.ComponentRef && this.ComponentRef.onActive)
                this.ComponentRef.onActive({});
        }
    }
    childCallback(e) {

        if (e.eventName === 'SHOW-MESSAGEBOX') {
            let vm = this.Data;
            const { options } = e;

            vm.Windows.forEach((wndw) => {
                wndw.isActive = false;
            });

            vm.Windows.push({ data: options, isActive: true, id: cntrl.Utils.getUniqueID(), isMessagebox: true });
            this.updateUI();
        }
        else if (e.eventName === 'ADD-WINDOW') {
            let vm = this.Data;
            const { options } = e;
            if (options) {
                const { url, data, onClose, isModal } = options;
                if (url) {
                    vm.Windows.forEach((wndw) => {
                        wndw.isActive = false;
                    });
                    vm.Windows.push({ url: url, data: data, isActive: true, id: cntrl.Utils.getUniqueID(), isMessagebox: false, onClose: onClose, showloading: false, isModal: isModal });
                    this.updateUI();
                }
            }
        }
        else if (e.eventName === 'WINDOW-CLOSE') {
            if (e.component && e.component.onClosing) {
                let status = e.component && e.component.onClosing();
                if (status === false)
                    return;
            }
            this.childCallback({ eventName: 'CLOSE-WINDOW', id: e.id });
        }
        else if (e.eventName === 'CLOSE-WINDOW' || e.eventName === 'MBOX-CLOSE') {
            let vm = this.Data;
            let wndw = vm.Windows.first(w => w.id === e.id);
            if (wndw) {
                let onClose = wndw.onClose;
                if (e.eventName === 'MBOX-CLOSE') {
                    if (wndw.data)
                        onClose = wndw.data.onClose || null;
                }
                try {
                    this.invokeCallback(onClose, e.data);
                }
                catch{ }
                vm.Windows.remove(wndw);
                this.setActiveContainer();
            }
            this.updateUI();
        }
        else if (e.eventName === 'ESC') {
            let vm = this.Data;
            let wndw = vm.Windows.first(w => w.isActive === true);
            if (wndw) {
                const args = { eventName: 'WINDOW-CLOSE', id: wndw.id, type: 'window' };
                if (wndw.isMessagebox === true)
                    args.eventName = 'MBOX-CLOSE';
                this.childCallback(args);
                return;
            }
            else {
                const args = { eventName: 'CLOSE', id: e.id, type: 'tab' };
                this.childCallback(args);
                return;
            }
        }
        else if (e.eventName === 'CLOSE') {
            if (e.type === 'window') {
                e.eventName = 'CLOSE-WINDOW';
                this.childCallback(e);
            }
            else if (e.type === 'tab' || e.type === 'tabbedWindow') {
                e.eventName = 'TAB-CLOSE';
                this.invokeParentCallback(e);
            }
        }
        else if (e.eventName === 'TAB-CLOSE') {
            this.invokeParentCallback(e);
        }
        else if (e.eventName === 'ADD-TAB') {
            this.invokeParentCallback(e);
        }
        else if (e.eventName === 'UPDATE-TAB-INFO') {
            this.invokeParentCallback(e);
        }
    }
}