import React from 'react';
import PropTypes from 'prop-types';
import * as witEnum from '../WITEnum';
import * as cntrl from '../WITUtils';

// const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
//     if (relatedTarget === null) return false;

//     var node = relatedTarget.parentNode;

//     while (node !== null) {
//       if (node === currentTarget) return true;
//       node = node.parentNode;
//     }

//     return false;
//   }

export class WITTextbox extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        inputType: PropTypes.oneOf(Object.keys(witEnum.WITTextboxTypes)),
        prefix: PropTypes.number,
        suffix: PropTypes.number,
        numericType: PropTypes.oneOf(Object.keys(witEnum.WITNumericTypes)),
        maxLength: PropTypes.number,
        mandatory: PropTypes.bool,
        style: PropTypes.object,
        allowClear: PropTypes.bool,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        events: PropTypes.object,
        onChange: PropTypes.func,
        leftIcons: PropTypes.array,
        rightIcons: PropTypes.array,
        textAlign: PropTypes.string,
        isDatePicker: PropTypes.bool,
        inputStyle: PropTypes.object,
        formatOnBlur: PropTypes.bool
    };
    static defaultProps = {
        name: '',
        inputType: witEnum.WITTextboxTypes.textbox,
        numericType: 'both',
        prefix: 3,
        suffix: 2,
        value: '',
        mandatory: false,
        style: { width: '100%' },
        inputStyle: undefined,
        allowClear: true,
        placeholder: '',
        disabled: false,
        readOnly: false,
        events: undefined,
        onChange: undefined,
        maxLength: undefined,
        leftIcons: [],
        rightIcons: [],
        textAlign: undefined,
        isDatePicker: false,
        formatOnBlur: true
    };
    state = { value: this.props.value, randamNo: cntrl.Utils.randamNo() };

    componentWillReceiveProps(props) {
        this.setState({ value: props.value });
    }

    focus(select) {
        if (this.textInput) {
            this.textInput.focus();

            if (select === true)
                this.textInput.select();
        }
    };

    //https://www.w3resource.com/javascript/form/credit-card-validation.php
    //email::- /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    //phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    getRegExp() {
        let testers = [];
        if (this.props.inputType == witEnum.WITTextboxTypes.numeric) {
            var prefix = this.props.prefix;
            var suffix = this.props.suffix;
            var neg = "";
            if (this.props.numericType === 'both')
                neg = '(-)?';
            else if (this.props.numericType === 'negative')
                neg = '-';
            if (prefix !== 0 || suffix !== 0) {
                if (suffix <= 0) {
                    testers.push(new RegExp('^' + neg + '[0-9]{0,' + prefix + '}$'));
                }
                else {
                    testers.push(new RegExp('^' + neg + '[0-9]{0,' + prefix + '}\\.[0-9]{0,' + suffix + '}$|^' + neg + '[0-9]{0,' + prefix + '}\\.$|^' + neg + '[0-9]{0,' + prefix + '}$'));
                }
            }
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric) {
            testers.push(new RegExp('^[0-9a-zA-Z]+$'));
        }
        else if (this.props.inputType == witEnum.WITTextboxTypes.time) {
            testers.push(new RegExp('^([0-9]|[0-1][0-2])(:([0-5]|[0-5][0-9])?)?$'));
            testers.push(new RegExp('^([0-9]|[0-1][0-2])(.([0-5]|[0-5][0-9])?)?$'));
        }
        else if (this.props.inputType == witEnum.WITTextboxTypes.time24hr) {
            testers.push(new RegExp('^([0-9]|[0-1][0-9]|[2][0-3])(:([0-5]|[0-5][0-9])?)?$'));
            testers.push(new RegExp('^([0-9]|[0-1][0-9]|[2][0-3])(.([0-5]|[0-5][0-9])?)?$'));
        }
        return testers;
    };

    invokeOnChange(evt, value) {
        if (this.props["onChange"]) {
            this.props["onChange"]({
                event: evt,
                value: value,
                eventName: "onChange",
                name: this.props.name
            });
        }

    };
    invokeCallback(callbackName, evt) {

        if (callbackName === "onChange") {
            this.invokeOnChange(evt, evt.target.value);
        }
        else if (callbackName === "onClear") {
            if (this.props[callbackName]) {
                this.props[callbackName]({
                    event: null,
                    value: '',
                    eventName: callbackName,
                    name: this.props.name
                });
            }
        }
        else {
            if (this.props.events && typeof this.props.events[callbackName] == "function") {
                this.props.events[callbackName]({
                    event: evt,
                    value: evt.target.value,
                    eventName: callbackName,
                    name: this.props.name
                });
            }
        }
    };
    handleChange = (evt, eventName) => {

        if (this.props.inputType === witEnum.WITTextboxTypes.titleCase) {
            cntrl.TextboxUtil.titleCaseInput(evt);
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.sentenceCase) {
            cntrl.TextboxUtil.sentenceCaseInput(evt);
        }
        else {

            let value = evt.target.value;
            if (value.length === 0)
                return;
            let testers = this.getRegExp();
            if (testers.length > 0) {
                var failedResults = testers.filter(i => i.test(value));
                if (failedResults.length === 0) {
                    evt.target.value = this.state.value;
                }
            }
        }
        this.invokeCallback('onChange', evt);
    };

    clearText = () => {
        this.setState({ value: '' });
        this.invokeCallback('onClear', null);
        this.invokeOnChange(null, "");
    };
    onChange = (evt) => {
        let val = evt.target.value;
        if (this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric)
            val = val.toUpperCase();
        this.setState({ value: val });
        this.invokeCallback('onChange', evt);
    };
    onBlur = (evt) => {
        if (evt.relatedTarget === undefined || evt.relatedTarget === null)
            return;
        //console.log(evt);
        //         if ( evt.currentTarget.contains( evt.relatedTarget ) ) {
        //             console.log(evt);
        //         //if ( !e.currentTarget.contains( document.activeElement ) ) {
        //       //  if (!focusInCurrentTarget(evt)) {
        //     return;
        //   }

        let onChangeEvent = false;
        if (this.props.inputType === witEnum.WITTextboxTypes.numeric) {
            let value = evt.target.value;
            let val = value;
            if (this.props.formatOnBlur === true)
                val = value ? parseFloat(value).toFixed(this.props.suffix) : '';
            if (isNaN(val)) {
                value = '';
            }
            else
                value = val;

            if (evt.target.value !== value)
                onChangeEvent = true
            evt.target.value = value;
            //this.invokeCallback('onBlur', evt);
        }
        // else if (this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric) {
        //     evt.target.value = evt.target.value;
        // }
        else if (this.props.inputType === witEnum.WITTextboxTypes.time || this.props.inputType === witEnum.WITTextboxTypes.time24hr) {
            let text = evt.target.value;
            if (text.length !== 0) {
                var splitChar = text.indexOf(":") > -1 ? ':' : text.indexOf(".") > -1 ? '.' : ' ';
                if (splitChar === ' ') {
                    if (text.length > 4)
                        text = text.substr(0, 4);
                    if (text.length === 1)
                        text = text.padStart(2, '0');
                    text = text.padEnd(4, '0');
                }
                else {
                    var data = text.split(splitChar);
                    text = data[0].padStart(2, '0');
                    text = text.substr(text.length - 2);
                    text += data[1].padEnd(2, '0').substr(0, 2);
                }
                var hr = +(text.substr(0, 2));
                var min = +(text.substr(2, 2));

                if (hr < 24 && min < 60)
                    text = text.substr(0, 2) + ":" + text.substr(2);
                else
                    text = "";

                if (evt.target.value !== text)
                    onChangeEvent = true
                evt.target.value = text;
            }
        }
        if (onChangeEvent === true)
            this.invokeCallback('onChange', evt);
        this.invokeCallback('onBlur', evt);
    };
    onPaste = (evt) => {
        var items = evt.clipboardData.items;
        if (items[0].kind === 'string') {
            var clipboardData = evt.clipboardData || window.clipboardData;
            var pastedData = clipboardData.getData('Text');
            var val = null;
            if (this.props.inputType === witEnum.WITTextboxTypes.titleCase)
                val = cntrl.TextboxUtil.titleCasePasteInput(evt, pastedData);
            else if (this.props.inputType === witEnum.WITTextboxTypes.sentenceCase)
                val = cntrl.TextboxUtil.sentenceCasePasteInput(evt, pastedData);
            if (val != null) {
                this.onChange(evt);
            }
        }
    }
    onKeyPress = (evt) => {
        this.handleChange(evt, "onKeyPress");
    };
    onInput = (evt) => { this.handleChange(evt, "onInput"); };

    getEvents() {
        let events = { onChange: this.onChange };

        if (this.props.inputType === witEnum.WITTextboxTypes.titleCase || this.props.inputType === witEnum.WITTextboxTypes.sentenceCase) {
            events.onKeyPress = this.onKeyPress;
            events.onPaste = this.onPaste;
        }
        if (this.props.inputType === witEnum.WITTextboxTypes.numeric || this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric || this.props.inputType == witEnum.WITTextboxTypes.time || this.props.inputType == witEnum.WITTextboxTypes.time24hr) {
            events.onInput = this.onInput;
        }
        events.onBlur = this.onBlur;
        events = Object.assign({}, this.props.events, events);
        return events;
    }
    render() {
        //<input type="text" class="textbox-text validatebox-text" autocomplete="off" placeholder="" style="margin: 0px 18px 0px 0px; padding-top: 0px; padding-bottom: 0px; height: 22px; line-height: 22px;" />
        let spanClassList = ['input-group'];
        let textClassList = ['form-control'];//, 'validatebox-text'
        let showClear = false;
        if (!this.props.disabled && !this.props.readOnly) {
            if (this.props.allowClear)
                showClear = true;
        }

        let style = Object.assign({}, WITTextbox.defaultProps.style, this.props.style);
        let attr = { type: "text" };
        if (this.props.name)
            attr.name = this.props.name;

        if (this.props.id)
            attr.id = this.props.id;

        if (this.props.inputType === witEnum.WITTextboxTypes.password)
            attr.type = "password";

        if (this.props.disabled || this.props.readOnly) {
            spanClassList.push('wittextbox-disabled');
            if (this.props.readOnly)
                attr.readOnly = true;
            else
                attr.disabled = true;
        }
        if (this.props.maxLength)
            attr.maxLength = this.props.maxLength || 0;

        let barClassNames = 'md-bar';
        if (this.props.mandatory) {
            attr.status = "mandatory";
            barClassNames = 'md-bar mandatory';
        }

        if (this.props.inputType === witEnum.WITTextboxTypes.numeric) {
            if (this.props.suffix && this.props.suffix > 0) {
                textClassList.push('text-right');
                attr.maxLength = this.props.prefix + this.props.suffix + 1 + ((this.props.numericType === 'negative' || this.props.numericType == 'both') ? 1 : 0);
            }
            else {
                textClassList.push('text-center');
                attr.maxLength = this.props.prefix || 0;
            }
        }
        else if (this.props.inputType === witEnum.WITTextboxTypes.alphaNumeric)
            textClassList.push('text-uppercase');

        if (this.props.textAlign) {
            if (this.props.textAlign === 'center')
                textClassList.push('text-center');
            else if (this.props.textAlign === 'right')
                textClassList.push('text-right');
            else
                textClassList.push('text-left');
        }
        if (this.props.inputStyle) {
            attr.style = this.props.inputStyle || {};
        }

        style.position = "relative";
        const events = this.getEvents();

        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

        let elmnt = null;
        if (isChrome)
            elmnt = (<input ref={el => this.textInput = el} autoComplete="disabled" autofill="off" className={textClassList.join(' ')} placeholder={this.props.placeholder} value={this.state.value} {...attr} {...events} />);
        else
            elmnt = (<input ref={el => this.textInput = el} autoComplete={this.state.randamNo} autofill="off" className={textClassList.join(' ')} placeholder={this.props.placeholder} value={this.state.value} {...attr} {...events} />);

        return (<React.Fragment>
            {elmnt}
            {showClear && (<span className="action-clear" onClick={this.clearText}></span>)}
            <span className={barClassNames}></span>
        </React.Fragment>);

        /* return (
             <input ref={el => this.textInput = el} className={textClassList.join(' ')} placeholder={this.props.placeholder} value={this.state.value} {...attr} {...events} />
         );*/
    };

};

