import React from 'react';
import WITBase from '../WITBase';
//import "../../css/xenia-bootstrap-alt.css";
import '../../wit-components/WITTab/WITTab.css';
import "../../css/xenia-css/xenia-pms.css";
import * as cntrl from '../../wit-components';
import AppContainer from '../AppContainer';
import AppVM from './AppVM';

export default class App extends WITBase {
    constructor(props) {
        super(props, new AppVM());
        cntrl.Utils.loadConfig();
        this.state = { IsLogged: false };
        this.childCallback = this.childCallback.bind(this);
    }
    childCallback(e) {
        if (this.VM)
            this.VM.childCallback(e);
    }
    componentDidMount() {
        document.title = 'XeniaOne'
      }
    render() {
        let model = this.VM.Data;
        let attr = { context: { type: 'tab', parentCallback: this.childCallback } };
        if (model.IsLogged === true) {
            attr.context.id = 'MAIN_APP';
            attr.id = "MAIN_APP";
            if( model.Module === 'PMS')
                attr.url = "Main";
            else
                attr.url = "CNBMain";
            attr.data = null
            return (<AppContainer key="MAIN_APP" {...attr} />);
        }
        else {
            attr.context.id = 'LOGIN_ID';
            attr.id = "LOGIN_ID";
            attr.url = "Login";
            attr.data = null
            return (<AppContainer key="LOGIN_ID"  {...attr} />);
        }
    }
}
