import moment from 'moment';

function DefaultEqualityComparer(a, b) {
    return a === b || a.valueOf() === b.valueOf();
};
function DefaultSortComparer(a, b) {
    if (a === b) return 0;
    if (a == null) return -1;
    if (b == null) return 1;
    if (typeof a == "string") return a.toString().localeCompare(b.toString());
    return a.valueOf() - b.valueOf();
};
function DefaultPredicate() {
    return true;
};
function DefaultSelector(t) {
    return t;
};
// interface Array<T> {
//     // select<U>(callbackfn: (value: T, index: number, array: T[]) => U): U[];
//     // selectMany<U>(callbackfn: (value: T) => U, thisArg?: any): U[];
//     // take(count: number): T[];
//     // skip(count: number): T[];
//     // count(callbackfn: (value: T, index: number, array: T[]) => U): number;
//     // count(callbackfn: (value: T, index: number, array: T[]) => U): number;
//     // //first(callbackfn: (value: T, index: number, array: T[]) => , def:T): T;
//     // some(callbackfn: (value: T, index: number, array: T[]) => boolean): boolean;
// }


Array.prototype.select = Array.prototype.map || function (selector, context) {
    context = context || window;
    var arr = [];
    var l = this.length;
    for (var i = 0; i < l; i++)
        arr.push(selector.call(context, this[i], i, this));
    return arr;
};
Array.prototype.selectMany = function (selector) {
    return this.aggregate(function (a, b) {
        return a.concat(selector(b));
    }, []);
};
Array.prototype.take = function (c) {
    return this.slice(0, c);
};
Array.prototype.skip = function (c) {
    return this.slice(c);
};
Array.prototype.count = function (predicate) {
    var l = this.length;
    if (!predicate) return l;
    var count = 0;
    for (var i = 0; i < l; i++) {
        if (predicate(this[i], i, this)) {
            count++;
        }
    }
    return count;
};
Array.prototype.first = function (predicate, def) {
    var l = this.length;
    if (!predicate) return l ? this[0] : def == null ? null : def;
    for (var i = 0; i < l; i++)
        if (predicate(this[i], i, this))
            return this[i];
    return def == null ? null : def;
};
Array.prototype.last = function (predicate, def) {
    var l = this.length;
    if (!predicate) return l ? this[l - 1] : def == null ? null : def;
    while (l-- > 0)
        if (predicate(this[l], l, this))
            return this[l];
    return def == null ? null : def;
};
Array.prototype.union = Array.prototype.concat;
Array.prototype.intersect = function (arr, comparer) {
    comparer = comparer || DefaultEqualityComparer;
    return this.distinct(comparer).where(function (t) {
        arr.contains(t, comparer);
    });
};
Array.prototype.except = function (arr, comparer) {
    if (!(arr instanceof Array)) arr = [arr];
    comparer = comparer || DefaultEqualityComparer;
    var l = this.length;
    var res = [];
    for (var i = 0; i < l; i++) {
        var k = arr.length;
        var t = false;
        while (k-- > 0) {
            if (comparer(this[i], arr[k])) {
                t = true;
                break;
            }
        }
        if (!t) res.push(this[i]);
    }
    return res;
};
Array.prototype.distinct = function (comparer) {
    var arr = [];
    var l = this.length;
    for (var i = 0; i < l; i++) {
        if (!arr.contains(this[i], comparer))
            arr.push(this[i]);
    }
    return arr;
};
// Array.prototype.indexOf = Array.prototype.indexOf || function (o, index) {
//     var l = this.length;
//     for (var i = Math.max(Math.min(index, l), 0) || 0; i < l; i++)
//         if (this[i] == o) return i;
//     return -1;
// };
Array.prototype.indexOfEx = function (predicate) {
    var l = this.length;
    if (!predicate) return -1;
    for (var i = 0; i < l; i++) {
        if (predicate(this[i], i, this)) {
            return i;
        }
    }
    return -1;
};
Array.prototype.lastIndexOf = Array.prototype.lastIndexOf || function (o, index) {
    var l = Math.max(Math.min(index || this.length, this.length), 0);
    while (l-- > 0)
        if (this[l] === o) return l;
    return -1;
};
Array.prototype.remove = function (item) {
    var i = this.indexOf(item);
    if (i !== -1)
        this.splice(i, 1);
};
Array.prototype.removeAll = function (predicate) {
    let item = null;
    let i = 0;
    while ((item = this.first(predicate))) {
        i++;
        this.remove(item);
    }
    return i;
};
Array.prototype.orderBy = function (selector, comparer) {
    comparer = comparer || DefaultSortComparer;
    return this.sort(function (a, b) {
        return comparer(selector(a), selector(b));
    });
};
Array.prototype.orderByDescending = function (selector, comparer) {
    return this.orderBy(selector, comparer).reverse();
};
Array.prototype.innerJoin = function (arr, outer, inner, result, comparer) {
    comparer = comparer || DefaultEqualityComparer;
    var res = [];
    this.forEach(function (t) {
        var g = arr.where(function (u) { return comparer(outer(t), inner(u)); });
        g.forEach(function (u) {
            res.push(result(t, u));
        });
    });
    return res;
};
Array.prototype.groupJoin = function (arr, outer, inner, result, comparer) {
    comparer = comparer || DefaultEqualityComparer;
    return this
        .select(function (t) {
            var key = outer(t);
            return {
                outer: t,
                inner: arr.where(function (u) { return comparer(key, inner(u)); }),
                key: key
            };
        })
        .select(function (t) {
            t.inner.key = t.key;
            return result(t.outer, t.inner);
        });
};
Array.prototype.groupBy = function (selector, comparer) {
    var grp = [];
    var l = this.length;
    comparer = comparer || DefaultEqualityComparer;
    selector = selector || DefaultSelector;
    for (var i = 0; i < l; i++) {
        var k = selector(this[i]);
        var g = grp.first((u) => { return comparer(u.key, k); });
        if (!g) {
            g = [];
            g.key = k;
            grp.push(g);
        }
        g.push(this[i]);
    }
    return grp;
};
Array.prototype.toDictionary = function (keySelector, valueSelector) {
    var o = {};
    var l = this.length;
    while (l-- > 0) {
        var key = keySelector(this[l]);
        if (key == null || key === "") continue;
        o[key] = valueSelector(this[l]);
    }
    return o;
};
Array.prototype.aggregate = Array.prototype.reduce || function (func, seed) {
    var arr = this.clone();
    var l = this.length;
    if (seed == null) seed = arr.shift();

    for (var i = 0; i < l; i++)
        seed = func(seed, arr[i], i, this);

    return seed;
};
Array.prototype.min = function (s) {
    s = s || DefaultSelector;
    var l = this.length;
    var min = s(this[0]);
    while (l-- > 0)
        if (s(this[l]) < min) min = s(this[l]);
    return min;
};
Array.prototype.max = function (s) {
    s = s || DefaultSelector;
    var l = this.length;
    var max = s(this[0]);
    while (l-- > 0)
        if (s(this[l]) > max) max = s(this[l]);
    return max;
};
Array.prototype.sum = function (s) {
    s = s || DefaultSelector;
    var l = this.length;
    var sum = 0;
    while (l-- > 0) sum += s(this[l]);
    return sum;
};
Array.prototype.where = function (predicate, context) {
    context = context || window;
    var arr = [];
    var l = this.length;
    for (var i = 0; i < l; i++)
        if (predicate.call(context, this[i], i, this)) arr.push(this[i]);
    return arr;
};
Array.prototype.any = function (predicate, context) {
    context = context || window;
    var f = this.some || function (p, c) {
        var l = this.length;
        if (!p) return l > 0;
        while (l-- > 0)
            if (p.call(c, this[l], l, this)) return true;
        return false;
    };
    return f.apply(this, [predicate, context]);
};
Array.prototype.all = function (predicate, context) {
    context = context || window;
    predicate = predicate || DefaultPredicate;
    var f = this.every || function (p, c) {
        return this.length === this.where(p, c).length;
    };
    return f.apply(this, [predicate, context]);
};
Array.prototype.contains = function (o, comparer) {
    comparer = comparer || DefaultEqualityComparer;
    var l = this.length;
    while (l-- > 0)
        if (comparer(this[l], o)) return true;
    return false;
};
Array.prototype.forEach = Array.prototype.forEach || function (callback, context) {
    context = context || window;
    var l = this.length;
    for (var i = 0; i < l; i++)
        callback.call(context, this[i], i, this);
};
Array.prototype.do = function (callback, context) {
    this.forEach(callback, context);
    return this;
};


Array.prototype.convert = function (selector,key,type) {
    key = key || 'key';
    type = type || 'type';
    if (selector && selector.length > 0 ) {
        var reformattedArray = this.map(function (obj) {
            var l = selector.length;
            for (var i = 0; i < l; i++) {
                if (selector[i][type] === "date") {
                    var momentVal = moment(obj[selector[i][key]], 'DDMMMYYYY', false);
                    obj[selector[i][key]] = momentVal.isValid() ? momentVal.toDate() : null;
                }
            }
            return obj;
        });
        return reformattedArray;
    }
    return this;
}