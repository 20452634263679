import React from "react";

export const WindowContext = React.createContext({windowAction:()=>{}});
export const WindowProvider = WindowContext.Provider;
export const WindowConsumer = WindowContext.Consumer;

export const TabContext = React.createContext({callback:(e)=>{}});
export const TabConsumer = TabContext.Consumer;
export const TabProvider = TabContext.Provider;


// export const AppContext = React.createContext({options: null});
// export const AppProvider = AppContext.Provider;
// export const AppConsumer = AppContext.Consumer;
