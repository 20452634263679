import React from 'react';
import PropTypes from 'prop-types';
import './WITTreeview.css';

export class WITTreeview extends React.Component {
    static ID = 0;

    static propTypes = {
        selectedItem: PropTypes.object,
        checkbox: PropTypes.bool,
        parent: PropTypes.object,
        items: PropTypes.array,
        onToggleStateChange: PropTypes.func,
        onCheckStateChange: PropTypes.func,
        onItemClick: PropTypes.func,
        onItemDoubleClick: PropTypes.func
    };
    static defaultProps = {
        selectedItem: null,
        checkbox: false,
        items: [],
        onToggleStateChange: undefined,
        onCheckStateChange: undefined,
        onItemClick: undefined,
        onItemDoubleClick: undefined
    };

    constructor(props) {
        super(props);
        this.clickCount = 0;
    }

    static handleToggleStateChange(e) {
        e.item.isExpanded = !e.item.isExpanded;
    }
    static handleCheckStateChange(e) {
        const handler = (item) => {
            var itm = null;
            const itemList = item.items || [];
            for (var i = 0; i < itemList.length; i++) {
                itm = itemList[i]
                itm.isSelected = item.isSelected;
                handler(itm);
            }
        };
        e.item.isSelected = !e.item.isSelected;
        handler(e.item);
        if (e.parent !== undefined && e.parent != null && e.parent.items)
            e.parent.isSelected = e.parent.items.length === e.parent.items.count(i => i.isSelected);
    }


    toggleStateChange = (e) => {
        if (this.props.onToggleStateChange)
            this.props.onToggleStateChange(e);
    };
    checkStateChange = (e) => {
        if (this.props.onCheckStateChange)
            this.props.onCheckStateChange(e);
    };

    renderNodes(level) {
        return this.props.items.map((itm, i) => <WITTreenode key={(WITTreeview.ID++)} selectedItem={this.props.selectedItem} parent={this.props.parent} onItemClick={this.props.onItemClick} onItemDoubleClick={this.props.onItemDoubleClick} onCheckStateChange={this.checkStateChange} onToggleStateChange={this.toggleStateChange} item={itm} checkbox={this.props.checkbox} level={level} />);
    }
    render() {
        const level = (this.props.level || 0) + 1;
        if (level === 1) {
            return (<ul className="wittree">
                {this.renderNodes(level)}
            </ul>);
        }
        else {
            const styles = { display: 'block' };
            return (<ul style={styles}>
                {this.renderNodes(level)}
            </ul>);
        }
    }
}
export class WITTreenode extends React.Component {
    static ID = 0;
    static propTypes = {
        selectedItem: PropTypes.object,
        checkbox: PropTypes.bool,
        parent: PropTypes.object,
        item: PropTypes.shape({
            isSelected: PropTypes.bool,
            isExpanded: PropTypes.bool,
            text: PropTypes.string,
            items: PropTypes.array
        }),
        onToggleStateChange: PropTypes.func,
        onCheckStateChange: PropTypes.func,
        onItemClick: PropTypes.func,
        onItemDoubleClick : PropTypes.func
    };
    static defaultProps = {
        checkbox: false,
        item: { isSelected: false, isExpanded: false, text: '', items: [] },
        onToggleStateChange: undefined,
        onCheckStateChange: undefined,
        onItemClick: undefined,
        onItemDoubleClick : undefined
    };

    componentCheckStateChange = (e) => {
        if (this.props.onCheckStateChange) {
            if (e.parent === undefined)
                e.parent = this.props.item;
            this.props.onCheckStateChange(e);
        }
    };
    componentDoubleClick = (evt) => {
        if (evt.target && evt.target.type === 'checkbox')
            return;
        if (this.props.onItemDoubleClick) {
            this.stopClick = true;
            this.props.onItemDoubleClick({ item: this.props.item, parent: this.props.parent, type: evt.type });
        }
    }
    componentClick = (evt) => {
        if (evt.target && evt.target.type === 'checkbox')
            return;
            
        let eventType = evt.type;
        this.clickCount = +this.clickCount+ 1;

        window.setTimeout(() => {
            if ((!this.stopClick || !this.props.onItemDoubleClick)) {
                this.clickCount = 0;
                if (this.props.onItemClick) {
                    this.props.onItemClick({ item: this.props.item, parent: this.props.parent, type: eventType });
                }
            }
            else {
                this.clickCount--;
                if (this.clickCount < 1) {
                    this.stopClick = false;
                    this.clickCount = 0;
                }
            }
        }, 250);
    };
    toggleStateChange = (evt) => {
        if (this.props.onToggleStateChange)
            this.props.onToggleStateChange({ item: this.props.item });
        evt.preventDefault();
        evt.stopPropagation();
    };
    checkStateChange = (evt) => {
        if (this.props.onCheckStateChange)
            this.props.onCheckStateChange({ item: this.props.item });
    };

    renderLevel() {
        const level = (this.props.level || 1) - 1;
        const indents = [];
        for (let i = 0; i < level; i++) {
            indents.push(<span key={(WITTreenode.ID++)} className="wittree-indent"></span>);
        }
        if (level > 0) {
            const subItems = this.props.item.items || [];
            if (subItems.length === 0)
                indents.push(<span key={(WITTreenode.ID++)} className="wittree-indent"></span>);
        }
        return indents;
    }
    renderIcon() {
        if (this.props.item.iconClass)
            return (<i className={this.props.item.iconClass} />);
        return null;
    }
    renderCheckbox() {
        return <input type="checkbox" checked={this.props.item.isSelected} onChange={this.checkStateChange} />;
    }
    renderSubitemIcon() {
        if (this.props.item.isExpanded)
            return <span className="wittree-hit wittree-expanded" onClick={this.toggleStateChange}></span>;
        else
            return <span className="wittree-hit wittree-collapsed" onClick={this.toggleStateChange}></span>;
    }
    renderSubitems() {
        const level = (this.props.level || 1);
        const attrs = {
            selectedItem: this.props.selectedItem,
            level: level,
            checkbox: this.props.checkbox,
            parent: this.props.item,
            items: this.props.item.items,
            onItemDoubleClick: this.props.onItemDoubleClick,
            onItemClick: this.props.onItemClick,
            onToggleStateChange: this.props.onToggleStateChange,
            onCheckStateChange: this.componentCheckStateChange,
        };
        return <WITTreeview {...attrs}></WITTreeview>;
    }
    render() {
        const classNameList = ['wittree-title'];
        if (this.props.item.className)
            classNameList.push(this.props.item.className);

        if (this.props.selectedItem && this.props.selectedItem == this.props.item)
            classNameList.push('wittree-node-selected');

        let styles = null;
        if (this.props.item.styles)
            styles = this.props.item.styles;

        const subItems = this.props.item.items || [];
        return (<li>
            <div className="wittree-node" onClick={this.componentClick} onDoubleClick={this.componentDoubleClick} >
                {this.renderLevel()}
                {subItems.length > 0 && this.renderSubitemIcon()}
                {this.props.checkbox && this.renderCheckbox()}
                {this.renderIcon()}
                <span style={styles} className={classNameList.join(' ')}>{this.props.item.text}</span>

            </div>
            {(subItems.length > 0 && this.props.item.isExpanded) && this.renderSubitems()}
        </li>);
    }
}

