import React from 'react';

export class WITIFrame extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        let url = 'http://localhost:53227/Report.aspx';
        return (
            <div>
                <iframe frameBorder={0} seamless="seamless"
                    style={{ width: '100%', border: 'none' }}
                    src={url}
                    ref={(f) => { this.ifr = f; }} title="tes"
                />
            </div>
        );
    }
};