// import React, { Suspense } from 'react';
// /* object should same aotherwise it will reload container. Check tab, Mdi Container, Window */
// const AsyncLoader = React.memo(({url, id, data, context, childRef}) => {
//     console.log('AsyncLoader');
//     var DynamicComponent = React.lazy(() => import(`../components/${url}`));
//     return (<Suspense fallback={<div>Loading...</div>}>
//         <DynamicComponent key={id} id={id} data={data} context={context} ref={(e) => { if(childRef) childRef(e)}} />
//     </Suspense>);
// });

// export {AsyncLoader};

import React, { Suspense } from 'react';
import { ErrorBoundary } from './ErrorBoundary';

export class AsyncLoader extends React.Component {
    constructor(props) {
        super(props);
        this.DynamicComponent = React.lazy(() => import(`../components/${props.url}`));
    }
    shouldComponentUpdate(nextProps) {
        return false;
    }
    render() {
        let { id, url, ...attr } = this.props;
        return (<ErrorBoundary><Suspense fallback={<div>Loading...</div>}>
            <this.DynamicComponent key={id} id={id} {...attr} ref={(e) => { if (this.props.childRef) this.props.childRef(e) }} />
        </Suspense>
        </ErrorBoundary>);
    }
}