import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { WITTextbox } from '../WITTextbox';

/* eslint-disable */
class WITPlaceSearch extends React.Component{
    constructor(props) {        
        super(props);
        this.autocomplete=null;
        this.placeListner=null;
    }
    
    componentDidMount()
    {
      window.setTimeout(()=>{this.initPlaceSearch()},100);
    }
    componentWillUnmount()
    {
        let google = google || null;
        try{

            if(google)
            {
                google.maps.event.removeListener(this.placeListner);
                google.maps.event.clearInstanceListeners(this.autocomplete);
            }
        }
        catch(ex){}
    }
    static propTypes = {
        name: PropTypes.string,
        mandatory: PropTypes.bool,
        style: PropTypes.object,
        allowClear: PropTypes.bool,
        placeHolder: PropTypes.string,
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        onPlaceSelected: PropTypes.func,
        isCitySearch:PropTypes.bool
    };
    static defaultProps = {
        name: '',
        mandatory: false,
        style: { width: '100%' },
        allowClear: true,
        placeHolder: '',
        readOnly: false,
        disabled: false,
        onPlaceSelected: undefined,
        isCitySearch:false
    };
    initPlaceSearch()
    {
      
        let inputControl = findDOMNode(this.inputPlaceSearch);
        var options = {
            types: ['(cities)']
           };
        if(!this.props.isCitySearch)
            options={}
        this.autocomplete = new google.maps.places.Autocomplete((this.inputPlaceSearch.textInput),options);
        this.placeListner=this.autocomplete.addListener('place_changed',()=>{
            this.getAddress();
        });
    }
    focus=()=>
    {        
        try{
            this.inputPlaceSearch.focus();
        }
        catch(ex){}
    }
    getAddress=()=>{ 
        var place = this.autocomplete.getPlace();
        if (place) { 
            let lat=place.geometry.location.lat();
            let lng=place.geometry.location.lng();
            let response={
                placeName:place.name,
                Address:place.formatted_address,
                geocode:{lat:lat, lng:lng},
                country:{},
                province:{},
                city:{}
            }
            place.address_components.map((item,index)=>{

                    if(item.types.includes("country"))
                    {
                        response.country={
                            code:item.short_name,
                            name:item.long_name
                        }                              
                    }
                    if(item.types.includes("administrative_area_level_1"))
                    {
                        response.province={
                            code:item.short_name,
                            name:item.long_name
                        }  
                    }
                    if(item.types.includes("locality"))
                    {
                        response.city={
                            code:item.short_name,
                            name:item.long_name
                        }  
                    }
            })
            if(this.props.onPlaceSelected)
            {
                this.props.onPlaceSelected('PLACE_CHANGED',response,place);
            }
        }
    }
    onClearChange =()=>{
            if(this.props.onPlaceSelected)
            {
                this.props.onPlaceSelected();
            }
    }
    render()
    {
        let inputAttr = {};
        inputAttr.textAlign = 'left';
        inputAttr.inputType = 'textbox';
        inputAttr.name = this.props.name;
        inputAttr.mandatory = this.props.mandatory;
        inputAttr.style = this.props.style;
        inputAttr.allowClear = this.props.allowClear;
        inputAttr.placeHolder = this.props.placeHolder;
        inputAttr.readOnly = this.props.readOnly;
        inputAttr.disabled = this.props.disabled;
        inputAttr.value = this.props.value;

        return (<WITTextbox ref={el => this.inputPlaceSearch = el} {...inputAttr} onClear={this.onClearChange} />);
    }
}
export {WITPlaceSearch}