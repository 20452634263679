import React from 'react';
import PropTypes from 'prop-types';
import { WITTextbox } from '../WITTextbox';
import { WITDropDown } from '../WITDropDown';
import moment from 'moment';
import { Utils } from '../WITUtils';
import $ from 'jquery';
import { WITCalendar } from '../WITCalendar';

export class WITDatepicker extends React.Component {
    static ID = 0;
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        selectedDate: PropTypes.object,
        mandatory: PropTypes.bool,
        style: PropTypes.object,
        allowClear: PropTypes.bool,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        openOnFocus: PropTypes.bool,
        keyNavigation: PropTypes.bool,
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        onDateChanged: PropTypes.func
    };
    static defaultProps = {
        name: undefined,
        selectedDate: null,
        mandatory: false,
        style: { width: '100%' },
        allowClear: true,
        placeHolder: '',
        readOnly: false,
        disabled: false,
        openOnFocus: true,
        keyNavigation: true,
        startDate: undefined,
        endDate: undefined,
        onDateChanged: undefined
    };
    state = {
        selectedDate: this.props.selectedDate,
        isOpen: false,
        id: WITDatepicker.ID++,
    };
    constructor(props) {
        super(props);
        this.IsCalendar = false;
    }

    focus() {
        if (this.textbox)
            this.textbox.focus();
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedDate: nextProps.selectedDate });
    }

    invokeDatechanged(dt) {
        if (this.props.onDateChanged)
            this.props.onDateChanged({ eventName: 'DATE-CHANGED', value: dt, date: dt, name: this.props.name });
    };

    moveTo(mode) {
        if (this.calendarRef) {
            this.calendarRef.moveTo(mode);
        }
        else {
            if (this.state.selectedDate && this.state.selectedDate instanceof Date) {
                var days = +1;
                if (mode === 'DOWN')
                    days = -1;
                const dt = new Date(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth(), (this.state.selectedDate.getDate() + days));
                this.setState({ selectedDate: dt });
            }
        }
    };
    handleEscape(evt) {
        if (this.calendarRef) {
            if (this.state.isOpen !== false) {
                this.setState({ isOpen: false });
                evt.stopPropagation();
                evt.preventDefault();
                if (evt.nativeEvent && evt.nativeEvent.stopImmediatePropagation)
                    evt.nativeEvent.stopImmediatePropagation();
                console.log('dp - handleEscape');
                return false;
            }
        }
    };
    handleOnEnterKey(evt) {
        var text = evt.target.value;
        var witState = { oldValue: (this.props.selectedDate || null), newValue: null, valueChanged: false };
        const val = $.trim(text);
        if (val.length === 0) {
            if (this.state.isOpen === true)
                this.moveTo('ENTER');
        }
        else {
            var dt = Utils.dateParse(val);
            if (dt != null) {
                if (!this.isvalidDate(dt))
                    dt = null;
            }
            witState.newValue = dt;

            var dt1 = '';
            if (witState.oldValue)
                dt1 = moment(witState.oldValue).format('DD MMM YYYY');
            var dt2 = '';
            if (witState.newValue)
                dt2 = moment(witState.newValue).format('DD MMM YYYY');

            if (dt2 === dt1) {
                if (this.state.isOpen == true)
                    this.moveTo('ENTER');
            }
            else {
                this.setState({ isOpen: false, selectedDate: witState.newValue });
            }
        }
    };
    isvalidDate(dt) {
        let isvalid = !(this.props.blockedDates || []).any(dy => (dy.getFullYear() == dt.getFullYear() && dy.getMonth() == dt.getMonth() && dy.getDate() == dt.getDate()));
        var isAfter = true;
        if (this.props.startDate && this.props.startDate instanceof Date) {
            isAfter = moment(dt).isSameOrAfter(this.props.startDate);
        }

        var isBefore = true;
        if (this.props.endDate && this.props.endDate instanceof Date) {
            isBefore = moment(dt).isSameOrBefore(this.props.endDate);
        }
        if ((!isBefore) || (!isAfter))
            isvalid = false;
        return isvalid;
    }
    componentCallback = (e) => {
        if (e.eventName === 'DROP_DOWN-MOUSE_UP') {
            const evt = e.event;
            var datepickers = $(evt.target).closest('.dropdown-popup,.witdatepicker');
            if (datepickers.length === 0 || $(datepickers[0]).attr('cntrl-id') !== this.state.id.toString()) {
                if (this.state.isOpen !== false)
                    this.setState({ isOpen: false });
            }
        }
    };
    onClear = () => {
        this.setState({ selectedDate: null });
        this.invokeDatechanged(null);
    };
    onKeyDown = (evt) => {
        switch (evt.keyCode) {
            case 27://up
                return this.handleEscape(evt);
                break;
            case 38://up
                this.moveTo('UP');
                break;
            case 40://down
                this.moveTo('DOWN');
                break;
            case 37://left
                this.moveTo('LEFT');
                break;
            case 39://right
                this.moveTo('RIGHT');
                break;
            case 13://enter
                return this.handleOnEnterKey(evt);
                //if (this.state.isOpen == true) {
                //    this.moveTo('ENTER');
                //}
                //else {
                //}
                //return false;
                break;
            default:
                break;

        }
    };
    onFocus = (e) => {
        this.handleIconClick();
    };
    onBlur = (e) => {
        if (this.IsCalendar === true)
            return;

        var state = { selectedDate: null, isOpen: false };
        if (e && e.value) {
            const val = $.trim(e.value);
            if (val.length > 0) {
                var dt = Utils.dateParse(val);
                if (dt != null) {
                    if (!this.isvalidDate(dt))
                        dt = null;
                }
                state.selectedDate = dt;
            }
        }
        var dt1 = this.props.selectedDate || null;
        if (dt1)
            dt1 = dt1.valueOf();
        var dt2 = state.selectedDate || null;
        if (dt2)
            dt2 = dt2.valueOf();

        if (dt2 !== dt1 || this.state.isOpen !== state.isOpen)
            this.setState(state);

        if (dt2 !== dt1)
            this.invokeDatechanged(state.selectedDate);
    };
    onDateChanged = (e) => {
        if (this.state.isOpen != false)
            this.setState({ isOpen: false, selectedDate: e.date });
        this.invokeDatechanged(e.date);

    };
    handleIconClick = () => {
        if (this.state.isOpen !== true)
            this.setState({ isOpen: true });
    };
    handleMouseEnter = () => {
        this.IsCalendar = true;
    };
    handleMouseLeave = () => {
        this.IsCalendar = false;
    };

    renderCalendar() {
        let inputAttr = {};
        inputAttr.name = this.props.name;
        inputAttr.startDate = this.props.startDate;
        inputAttr.endDate = this.props.endDate;
        inputAttr.selectedDate = this.state.selectedDate;
        inputAttr.onDateChanged = this.onDateChanged;

        //const me = this;
        const dropDownAttr = { parent: this, parentCallback: this.componentCallback };
        return (<WITDropDown {...dropDownAttr}>
            <div cntrl-id={this.state.id} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className="dropdown-popup">
                <WITCalendar ref={el => this.calendarRef = el} {...inputAttr} />
            </div>
        </WITDropDown>);
    }

    render() {
        let inputAttr = {};
        inputAttr.textAlign = 'center';
        inputAttr.inputType = 'textbox';
        inputAttr.mandatory = this.props.mandatory;
        inputAttr.style = this.props.style;
        inputAttr.allowClear = this.props.allowClear;
        inputAttr.placeholder = this.props.placeholder;
        inputAttr.readOnly = this.props.readOnly;
        inputAttr.disabled = this.props.disabled;
        inputAttr.value = '';
        if (this.props.id)
            inputAttr.id = this.props.id;
        if (this.props.name)
            inputAttr.name = this.props.name;


        if (this.state.selectedDate && this.state.selectedDate instanceof Date) {
            inputAttr.value = moment(this.state.selectedDate).format('DD MMM YYYY');
        }

        inputAttr.events = {};

        let canEdit = false;
        if (!inputAttr.disabled && !inputAttr.readOnly) {
            canEdit = true;

            inputAttr.events.onBlur = this.onBlur;

            if (this.props.openOnFocus)
                inputAttr.events.onFocus = this.onFocus;

            if (this.props.keyNavigation)
                //inputAttr.events.onKeyDown = this.onKeyDown;
                inputAttr.events.onKeyDown = this.onKeyDown;

            if (!this.props.disabled)
                inputAttr.onClear = this.onClear;
        }

        return (<div cntrl-id={this.state.id} className="witdatepicker">
            <WITTextbox ref={el => this.textbox = el} {...inputAttr} />
            <span className="frm-icon" onClick={(e) => this.handleIconClick(e)}><i className="fa fa-calendar"></i></span>
            {(canEdit && this.state.isOpen) && this.renderCalendar()}
        </div>);
    };
};