import * as cntrl from '../wit-components';

export default class VMBase {
    constructor(props) {
        this.props = props;
        this.ComponentRef = null;
        this.Data = {};
        this.________isloaded = false;

        // if (props === undefined || props.context === undefined) {
        //     let state = cntrl.WITStorageManager().getState('XPMS_APP');
        //     if (state === null) {
        //         state = cntrl.WITStorageManager().addState(null, 'XPMS_APP');
        //     }
        //     else
        //         this.________isloaded = true;
        //     this.Data = state.vm;
        // }

    }
    setProps(props) {
        this.props = props;
    }
    Register(componentRef) {
        this.ComponentRef = componentRef;

    }
    Unregister() {
        this.ComponentRef = null;
    }
    updateUI() {
        if (this.ComponentRef != null)
            this.ComponentRef.setState({});
    }
    cleanObject(objectToClean) {
        Object.keys(objectToClean).forEach((param) => {
            try {
                if (param === 'props')
                    return;
                let tmp = objectToClean[param];
                if (tmp === undefined || tmp === null)
                    return;
                objectToClean[param] = undefined;
                /* if ((objectToClean[param]).toString() === "[object Object]") {
                     this.cleanObject(objectToClean[param]);
                 } else {
                     objectToClean[param] = undefined;
                 }*/
            } catch{ }
        })
    };

    invokeCallback(callback, e) {
        if (callback && typeof callback === 'function')
            return callback(e);
        return null;
    }
    invokeParentCallback(e) {
        const { context, id } = this.props;
        if (context !== undefined && context !== null) {
            const { parentCallback, type } = context;
            if (parentCallback) {
                e.id = id;
                e.type = type;
                this.invokeCallback(parentCallback, e);
            }
        }
    }
    showWindow(options) {
        this.invokeParentCallback({ eventName: 'ADD-WINDOW', options: options });
    }
    addTab(options, addToMainTab = false) {
        this.invokeParentCallback({ eventName: 'ADD-TAB', options: options, addToMainTab: addToMainTab });
    }
    close(result) {
        this.invokeParentCallback({ eventName: 'CLOSE', data: result });
        this.cleanObject(this);
    }
    closeTab(result) {
        this.invokeParentCallback({ eventName: 'CLOSE-TAB', data: result });
        this.cleanObject(this);
    }
    updateTabInfo(result) {
        this.invokeParentCallback({ eventName: 'UPDATE-TAB-INFO', data: result });
    }
    resizeWindow() {
        // if (this.props.parentCallback) {
        //     this.props.parentCallback({
        //         eventName: 'RESIZE-WINDOW'
        //     });
        // }
    }
    showMessageBox(options) {
        options.messageboxType = options.messageboxType || cntrl.WITMessageboxTypes.error;
        options.text = options.text || '';
        options.onClose = options.onClose || null;
        options.eventName = 'SHOW-MESSAGE-BOX';
        options.buttons = options.buttons || [{ text: "Ok" }];
        this.invokeParentCallback({ eventName: 'SHOW-MESSAGEBOX', options: options });
    }
}
