import * as React from 'react';
import * as PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import $ from 'jquery';

export class DropMenu extends React.Component {

    static propTypes = {
        bsStyle: PropTypes.object,
        bsClass: PropTypes.string,
        visibleAlign: PropTypes.string,
        menuWidth: PropTypes.string,
        dropLocation: PropTypes.string,
        bgMode: PropTypes.bool
    };
    static defaultProps = {

        bsStyle: undefined,
        bsClass: '',
        visibleAlign: 'right',
        menuWidth: '100%',
        bgMode: true
    };
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.registerDomEvent();
    }
    onDropClick(e) {
        this.closeAllOpenDrop();
        if (e.target.matches('.wit-no-hide')) {
            findDOMNode(this.dropMenuList).classList.toggle("show");
            this.checkState();
        }
    }
    closeAllOpenDrop() {
        $(findDOMNode(this.dropMenuElement)).removeClass('wit-drop-inactive');
        $('.wit-drop-menu.wit-drop-inactive.bg-gray').trigger('click');
        $(findDOMNode(this.dropMenuElement)).addClass('wit-drop-inactive');
    }
    checkState() {
        window.setTimeout(() => {
            let isOpen = $(findDOMNode(this.dropMenuList)).hasClass('show');
            this.setState({ isOpen });
        }, 50);
    }
    registerDomEvent() {
        $(document).on('click', ev => {
            if (!ev.target.matches('.wit-no-hide')) {
                $('.wit-drop-menu-list').removeClass('show');
                this.checkState();
            }
        });

    }
    render() {
        const children = this.props.children;
        let __classNames = ['wit-drop-menu wit-no-hide wit-drop-inactive'];
        let __subMenuClassNames = ['wit-drop-menu-list wit-no-hide'];
        let __mainCss = {};
        let subMenuStyle = {};
        let content = null;
        let items = null;
        if (this.props.bsStyle != undefined) {
            __mainCss = this.props.bsStyle;
            if (__mainCss.height != undefined) {
                subMenuStyle.top = __mainCss.height;

            }
        }
        if (this.props.visibleAlign != undefined) {
            switch (this.props.visibleAlign) {
                case "right":
                    subMenuStyle.right = "0px";
                    __subMenuClassNames.push('wit-caretview-right');
                    break;
                case "left":
                    subMenuStyle.left = "0px";
                    __subMenuClassNames.push('wit-caretview-left');
                    break;
                case "center":
                    subMenuStyle.left = this.props.dropLocation || '0px';
                    if (this.props.dropLocation != undefined)
                        __subMenuClassNames.push('wit-caretview-center');
                    else
                        __subMenuClassNames.push('wit-caretview-left');
                    break;
                default:
                    break;
            }
        }
        if (this.props.menuWidth !== undefined) {
            subMenuStyle.width = this.props.menuWidth;
        }
        if (this.props.bsClass !== undefined || this.props.bsClass !== '') {
            __classNames.push(this.props.bsClass);
        }
        if (this.state.isOpen) {
            __classNames.push('bg-gray');
        }
        if (!this.props.bgMode) {
            __classNames.push('no-bg');
        }
        React.Children.forEach(children, function (child) {
            if (child.type.displayName === "DropMenuContent" || child.type.name === "DropMenuContent") {
                content = child;
            }
            else {
                items = child;
            }
        });
        return (
            <div className={__classNames.join(' ')} ref={ref => (this.dropMenuElement = ref)} onClick={(e) => this.onDropClick(e)} style={__mainCss} >
                {content}
                <div className={__subMenuClassNames.join(' ')} style={subMenuStyle} ref={ref => (this.dropMenuList = ref)} >
                    {items}
                </div>
            </div>
        );
    };
};
export class DropMenuContent extends React.Component {
    render() {
        return (this.props.children);
    };
};