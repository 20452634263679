import React from 'react';
//import './WITMessagebox.css';
import * as witEnum from '../WITEnum';

const WITMessagebox = React.memo(({
    id,
    title,
    messageboxType,
    text,
    buttons,
    defaultButtonIndex,
    onClose
}) => {

    defaultButtonIndex = defaultButtonIndex || 0;
    if (title === undefined || title === null) {
        title = {
            icon: '',
            className: ''
        };
        title.text = 'Alert';
        if (messageboxType === witEnum.WITMessageboxTypes.error) {
            title.icon = 'fa fa-exclamation text-danger';
            title.className = 'text-danger';
        } else if (messageboxType === witEnum.WITMessageboxTypes.info) {
            title.icon = 'fa fa-info text-info';
            title.className = 'text-info';
        } else if (messageboxType === witEnum.WITMessageboxTypes.question) {
            title.icon = 'fa fa-question text-black';
            title.className = 'text-black';
        } else if (messageboxType === witEnum.WITMessageboxTypes.warning) {
            title.icon = 'fa fa-exclamation-triangle text-warning';
            title.className = 'text-warning';
        }
    }
    const btns = (buttons.map((item, index) => {
        return <button key={
            index
        }
            tabIndex={
                index
            }
            className={(index === defaultButtonIndex)?"btn btn-primary mr-1 btn-xs" : "btn btn-outline-primary mr-1 btn-xs"}
            onClick={
                (e) => {
                    onClose({ id, e, item, index, eventName: 'CLOSE' })
                }
            }
            type="button" >{item.text || 'Caption???'}</button>
    }));
    return (<div className="message-box-wrapper messagebox-zindex" >
        <div className="message-box rounded shadow-sm mb-5  rounded" >
            <div className="message-box-body" >
                <div className="message-icon">
                    <i className={title.icon + ' fa-2x'}></i>
                </div>
                <div className="message-content pl-3 pr-3">
                    <p className={title.className}>{text}</p>
                </div>
            </div>
            <div className="message-box-footer" >
                {btns}
            </div>
        </div>
    </div>);
});

export {
    WITMessagebox
};
