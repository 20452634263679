import $ from 'jquery';

export class DOMUtil {
    static LEFT_BUTTON = 0;
    static DRAG_THRESHOLD = 3;

    /*
    onKeyDown: this.handleTab, 
    getFocusableChildren() {
        if (this._element)
            return this._element.querySelectorAll(witEnum.FocusableElements.join(', '));
        else
            return [];
    }
    getFocusedChild() {
        let currentFocus;
        const focusableChildren = this.getFocusableChildren();

        try {
            currentFocus = document.activeElement;
        } catch (err) {
            currentFocus = focusableChildren[0];
        }
        return currentFocus;
    }
    handleTab(e) {
        if (e.which !== 9) return;

        const focusableChildren = this.getFocusableChildren();
        const totalFocusable = focusableChildren.length;
        if (totalFocusable === 0) return;
        const currentFocus = this.getFocusedChild();

        let focusedIndex = 0;

        for (let i = 0; i < totalFocusable; i += 1) {
            if (focusableChildren[i] === currentFocus) {
                focusedIndex = i;
                break;
            }
        }

        if (e.shiftKey && focusedIndex === 0) {
            e.preventDefault();
            focusableChildren[totalFocusable - 1].focus();
        } else if (!e.shiftKey && focusedIndex === totalFocusable - 1) {
            e.preventDefault();
            focusableChildren[0].focus();
        }
    }
    */
    static width = function (el) {
        return el.width() || 0;
    };
    static height = function (el) {
        return el.height() || 0;
    };
    static outerWidth = function (el) {
        if (el[0] === window) {
            return el.width() || document.body.clientWidth;
        }
        return el.outerWidth() || 0;
    };

    static focusable = function( element ) {
        var map, mapName, img,
            nodeName = element.nodeName.toLowerCase(),
            isTabIndexNotNaN = !isNaN( $.attr( element, "tabindex" ) );
        if ( "area" === nodeName ) {
            map = element.parentNode;
            mapName = map.name;
            if ( !element.href || !mapName || map.nodeName.toLowerCase() !== "map" ) {
                return false;
            }
            img = $( "img[usemap=#" + mapName + "]" )[0];
            return !!img && visible( img );
        }
        return ( /input|select|textarea|button|object/.test( nodeName ) ?
            !element.disabled :
            "a" === nodeName ?
                element.href || isTabIndexNotNaN :
                isTabIndexNotNaN) &&
            // the element and all of its ancestors must be visible
            visible( element );
    
        function visible( element ) {
          return $.expr.filters.visible( element ) &&
            !$( element ).parents().addBack().filter(function() {
              return $.css( this, "visibility" ) === "hidden";
            }).length;
        }
    }

	/**
	 * extend plugin to set box model height
	 */
    static outerHeight = function (el) {
        if (el[0] === window) {
            return el.height() || document.body.clientHeight;
        }
        return el.outerHeight() || 0;
    };

    static getBounds(parent)
    {
        let bounds = null;
        if (parent) {
            let areaBounds = parent[0].getBoundingClientRect();
            bounds = { left: areaBounds.left, top: areaBounds.top, right: areaBounds.right, bottom: areaBounds.bottom };
    }
    return bounds;
    }
    static getLeftEx(parent, width, isRightAlign) {
        var leftPos = parent.offset().left;
        if (isRightAlign) {
            leftPos += DOMUtil.outerWidth(parent) - width;
        }
        if (leftPos + width > DOMUtil.outerWidth($(window)) + $(document).scrollLeft()) {
            leftPos = DOMUtil.outerWidth($(window)) + $(document).scrollLeft() - width;
        }
        if (leftPos < 0) {
            leftPos = 0;
        }
        return leftPos;
    };
    static getTopEx(parent, height, popup) {
        var top = parent.offset().top + DOMUtil.outerHeight(parent);
        if (top + height > DOMUtil.outerHeight($(window)) + $(document).scrollTop()) {
            top = parent.offset().top - height;
        }
        if (top < $(document).scrollTop()) {
            top = parent.offset().top + DOMUtil.outerHeight(parent);
        }
        return top;
    };

    static getLeft(parent, popup, isRightAlign) {
        var leftPos = parent.offset().left;
        if (isRightAlign) {
            leftPos += DOMUtil.outerWidth(parent) - DOMUtil.outerWidth(popup);
        }
        if (leftPos + DOMUtil.outerWidth(popup) > DOMUtil.outerWidth($(window)) + $(document).scrollLeft()) {
            leftPos = DOMUtil.outerWidth($(window)) + $(document).scrollLeft() - DOMUtil.outerWidth(popup);
        }
        if (leftPos < 0) {
            leftPos = 0;
        }
        return leftPos;
    };
    static getTop(parent, popup, isBottomAlign) {
        var top = parent.offset().top;

        if (isBottomAlign)
            top += DOMUtil.outerHeight(parent);

        //var top = parent.offset().top + DOMUtil.outerHeight(parent);
        if (top + DOMUtil.outerHeight(popup) > DOMUtil.outerHeight($(window)) + $(document).scrollTop()) {
            top = parent.offset().top - DOMUtil.outerHeight(popup);
        }
        if (top < $(document).scrollTop()) {
            top = parent.offset().top + DOMUtil.outerHeight(parent);
        }
        const ht1 = $('body').height();
        const ht2 = DOMUtil.outerHeight(popup);
        const newTop = (ht1 - ht2);
        if (top > newTop) {
            top = newTop;
        }
        return top;
    };

    static getPostion(el) {
        var xPos = 0;
        var yPos = 0;

        while (el) {
            if (el.nodeName.toUpperCase() === "BODY") {
                // deal with browser quirks with body/window/document and page scroll
                var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
                var yScroll = el.scrollTop || document.documentElement.scrollTop;

                xPos += (el.offsetLeft - xScroll + el.clientLeft);
                yPos += (el.offsetTop - yScroll + el.clientTop);
            } else {
                // for all other non-BODY elements
                xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
                yPos += (el.offsetTop - el.scrollTop + el.clientTop);
            }

            el = el.offsetParent;
        }
        return {
            x: xPos,
            y: yPos
        };
    }
    static getLocation(event, constraint, node, parentNode, shiftX, shiftY) {
        var position = { x: node.getBoundingClientRect().left, y: node.getBoundingClientRect().top };

        var pX = shiftX;//parentNode.getBoundingClientRect().left;
        var pY = shiftY; //parentNode.getBoundingClientRect().top;
        var gX = event.pageX;
        var gY = event.pageY;

        var eW = node.getBoundingClientRect().width;
        var eH = node.getBoundingClientRect().height;


        var nX = gX - pX - (eW / 2);
        var nY = gY - pY - (eH / 2);

        if (nX > constraint[0] && nX < constraint[1] && nY > constraint[2] && nY < constraint[3]) {
            //element.style.left = nX + 'px';
            //element.style.top = nY + 'px';
            position.x = nX;
            position.y = nY;
        } else if (nX > constraint[0] && nX < constraint[1] && (nY <= constraint[2] || nY >= constraint[3])) {
            position.x = nX;
        } else if ((nX <= constraint[0] || nX >= constraint[1]) && nY > constraint[2] && nY < constraint[3]) {
            position.y = nY;
        }
        return position;
    }
    //http://kentwilliam.com/articles/rich-drag-and-drop-in-react-js
    static elementMove(event, node, container, callback) {
        let state = { mouseDown: false, dragging: false, originX: 0, originY: 0, elementX: 0, elementY: 0 };

        if (event.button === DOMUtil.LEFT_BUTTON) {
            event.preventDefault();

            addEvents();
            let pageOffset = node.getBoundingClientRect();
            let parentOffset = container.getBoundingClientRect();

            state.mouseDown = true;
            state.originX = event.pageX;
            state.originY = event.pageY;
            state.elementX = pageOffset.left - parentOffset.left;
            state.elementY = pageOffset.top - parentOffset.top;

            function onMouseMove(evt) {
                let deltaX = evt.pageX - state.originX;
                let deltaY = evt.pageY - state.originY;
                let distance = Math.abs(deltaX) + Math.abs(deltaY);
                if (!state.dragging && distance > DOMUtil.DRAG_THRESHOLD)
                    state.dragging = true;
                if (state.dragging) {
                    let x = state.elementX + deltaX + document.body.scrollLeft;
                    let y = state.elementY + deltaY + document.body.scrollTop;
                    if (x < 0)
                        x = 0;
                    if (y < 0)
                        y = 0;

                    let location = {
                        eventName: 'LOCATION',
                        x: x,
                        y: y
                    };
                    callback(location);
                }
            };
            function onMouseUp(evt) {
                removeEvents();
                state.dragging = false;
            };

            function addEvents() {
                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', onMouseUp);
            };
            function removeEvents() {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };
        }
    }
    static getElementPostion(node, location) {
        let pos = { x: 0, y: 0 };
        if (location === 'right') {
            let elementOffset = node.getBoundingClientRect();
            let parentOffset = node.parentNode.getBoundingClientRect();
            pos.x = parentOffset.width - elementOffset.width;
        }
        else if (location === 'middle') {
            let elementOffset = node.getBoundingClientRect();
            let parentOffset = node.parentNode.getBoundingClientRect();
            pos.x = (parentOffset.width - elementOffset.width) / 2.0;
        }
        else if (location === 'center') {
            let elementOffset = node.getBoundingClientRect();
            let parentOffset = node.parentNode.getBoundingClientRect();
            const width = elementOffset.width;
            const pWidth = parentOffset.width;

            const height = elementOffset.height;
            const pHeight = parentOffset.height;
            pos.x = (pWidth - width) / 2.0;
            pos.y = (pHeight - height) / 2.0;
            if (height < 55) {
                pos.x = 0;
            }
            if (width < 110) {
                pos.y = 0;
            }
        }
        if (pos.x < 0)
            pos.x = 0;
        if (pos.y < 0)
            pos.y = 0;
        return pos;
    }
    static triggerEvent(node, selector, e) {
        let cancelled = false;
        if (selector === undefined || selector == null) {
            selector = ".witcontainer-body, .witcontainer-body>*";
        }
        else
            selector += " .witcontainer-body>*";
        const elements = $(node).find(selector);
        for (var i = 0; i < elements.length; i++) {
            var event = new CustomEvent('witEvent', { detail: e, "bubbles": false, "cancelable": true });
            cancelled = !elements[i].dispatchEvent(event);
            if (cancelled)
                return cancelled;
            if (event.data)
                e.data = event.data;
        }
        return cancelled;
    }
    static setFocus(node, selector) {
        $(node).focus();
        window.setTimeout(() => {
            const elements = $(node).find(selector == null ? ".witcontainer-body" : selector + " .witcontainer-body>*");
            elements.focus();
        }, 100);
    }
}