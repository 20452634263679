import VMBase from '../VMBase';
import * as cntrl from '../../wit-components';

export default class AppVM extends VMBase {
    constructor(props) {
        super(props);
        this.init();
    }
    init() {
        const model = this.Data;
        if (Object.keys(model).length !== 0)
            return;
        model.IsLogged = false;
        model.Module = 'PMS';
        this.loadErrorMsgs();
    }
    loadErrorMsgs() {
        cntrl.Utils.ajax({ url: 'auth/initasync', data: null }, (r) => {
            cntrl.Utils.Errors = r || [];
        });
    };
    childCallback(e) {
        const model = this.Data;
        if (e.id === 'LOGIN_ID') {
            if (e.data && e.data.IsLogedIn === true) {
                model.Module = e.data.Module ||model.Module; 
                model.IsLogged = true;
                this.updateUI();
            }
        }
        else if (e.id === 'MAIN_APP') {
            if (e.data && e.data.IsLogedOff === true) {
                model.IsLogged = false;
                this.updateUI();
            }
        }
    }
}